import React from 'react';

import { WaysToSaveLarge } from './WaysToSaveLarge';
import { WaysToSaveSmall } from './WaysToSaveSmall';

export const WaysToSave = () => (
  <div id="more-ways-to-save">
    <WaysToSaveSmall />
    <WaysToSaveLarge />
  </div>
);
