import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { MoreWaysToSave } from './MoreWaysToSave';
import { SpendConfidently } from './SpendConfidently';

const HighlightsWrapper = styled(Flex)`
  max-width: 1680px;
  padding: 82px 40px 109px;
  margin: 0 auto;
  gap: 56px;

  > div {
    flex-basis: 50%;
  }
`;

export const HighlightsLarge = () => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <HighlightsWrapper>
      <SpendConfidently />
      <MoreWaysToSave />
    </HighlightsWrapper>
  </ShowOn>
);
