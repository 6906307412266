import React, { FC, SVGProps } from 'react';

export const Publix: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216 216"
    {...props}
  >
    <title>PUBLIX_brandmark</title>
    <circle fill="#fff" className="cls-1" cx="108" cy="108" r="105" />
    <path
      className="cls-2"
      fill="#3e8c2f"
      d="M231.53,224.47a6,6,0,1,1-6,6A6,6,0,0,1,231.53,224.47Zm0,11.13a5.13,5.13,0,1,0-5.13-5.13A5.12,5.12,0,0,0,231.53,235.6Zm-1.31-1.69h-.87V227H232c1.56,0,2.18.68,2.18,1.92a1.87,1.87,0,0,1-1.74,2l2.06,3h-1l-2.06-3h-1.24Zm0-3.81h1.11c1,0,2,0,2-1.19,0-.89-.81-1.15-1.55-1.15h-1.55Z"
      transform="translate(-36 -36)"
    />
    <path
      className="cls-2"
      fill="#3e8c2f"
      d="M148.44,114.44A24.56,24.56,0,1,0,173,139,24.55,24.55,0,0,0,148.44,114.44Z"
      transform="translate(-36 -36)"
    />
    <path
      className="cls-2"
      fill="#3e8c2f"
      d="M144,36A108,108,0,1,0,252,144,108,108,0,0,0,144,36Zm30.52,141.3a47.65,47.65,0,0,1-51.26,1.18v35.75H101.55v-78h0q.08-2.35.38-4.62a0,0,0,0,1,0,0q.3-2.27.82-4.46v0q.51-2.18,1.23-4.27l0,0q.71-2.07,1.62-4.05l0-.07q.9-2,2-3.81l.05-.09q1.07-1.84,2.3-3.55l.08-.12q1.22-1.7,2.6-3.27l.13-.15q1.36-1.55,2.87-3l.17-.16q1.49-1.41,3.11-2.68l.21-.16c1.08-.83,2.19-1.62,3.34-2.36l.24-.15q1.73-1.1,3.55-2l.26-.13q1.84-.94,3.76-1.72l.27-.1c1.3-.51,2.62-1,4-1.37l.24-.07q2.05-.6,4.18-1l.2,0c1.44-.27,2.9-.49,4.38-.63h.11c1.51-.14,3-.22,4.6-.22h.18a46.7,46.7,0,0,1,26.08,85.42Z"
      transform="translate(-36 -36)"
    />
  </svg>
);
