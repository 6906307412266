import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BenefitCardLarge } from './BenefitCardLarge';
import { Tab } from './moreWaysToSaveUtils';

const TabContent = styled.div`
  width: 100%;
`;

interface TabContentProps extends Tab {}

export const TabContentLarge: FC<TabContentProps> = ({
  content: { heading, body },
  cardItems,
}) => (
  <TabContent>
    <ResponsiveHeading variant={2} marginBottom={8}>
      {heading}
    </ResponsiveHeading>
    <Body variant={4} marginBottom={40}>
      {body}
    </Body>
    {cardItems.map((cardItem, idx) => (
      <BenefitCardLarge {...cardItem} key={idx} />
    ))}
  </TabContent>
);
