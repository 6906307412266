import React, { FC, SVGProps } from 'react';

export const Marriott: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="560.825px"
    height="178.309px"
    viewBox="0 0 9432.102 2998.84"
    {...props}
  >
    <path
      d="M1678.358,2990.029V2502.33h106.959v487.698H1678.358z M2332.133,2990.029
	l-236.111-309.932v310.086H1990.45V2502.33h98.636l227.789,300.348V2502.33h105.571v487.698H2332.133z M2807.128,2601.261v388.768
	h-106.959v-388.768h-147.954v-98.931h402.868v98.931H2807.128z M3084.543,2990.029V2502.33h366.803v95.376h-261.077v97.075h229.175
	v95.376h-229.175v104.341h264.007v95.376L3084.543,2990.029z M3926.959,2990.029l-104.801-155.971h-83.996v156.125h-106.959V2502.33
	h221.778c114.665,0,183.401,60.596,183.401,160.917v1.392c3.194,68.18-39.465,130.081-104.184,151.179l118.825,174.211H3926.959z
	 M3929.117,2668.812c0-46.373-31.903-69.715-83.996-69.715h-106.342v140.049h107.883c52.093,0,81.992-27.824,81.992-68.942
	L3929.117,2668.812z M4554.533,2990.029l-235.494-310.087v310.087h-105.572V2502.33h98.637l227.787,300.348V2502.33h105.572v487.698
	H4554.533z M5178.716,2990.029l-44.386-109.442h-206.059l-44.386,109.442H4774.77l208.369-491.254h98.637l208.368,491.254H5178.716z
	 M5031.07,2627.849l-64.576,158.135h129.152L5031.07,2627.849z M5570.487,2601.416v388.767h-106.959v-388.922h-147.954v-98.931
	h402.867v98.931L5570.487,2601.416z M5859.307,2990.183V2502.33h106.959v487.698L5859.307,2990.183z M6391.635,2998.531
	c-149.805,0.309-257.688-112.225-257.688-250.883v-1.392c0-139.121,109.115-252.273,259.074-252.273
	c149.957,0,257.687,112.225,257.687,250.883v1.391c0.154,138.658-108.808,252.583-258.92,252.583L6391.635,2998.531z
	 M6538.818,2746.256c2.81-81.822-61.047-150.438-142.626-153.256c-1.519-0.053-3.038-0.081-4.558-0.087
	c-86.152,0-145.951,68.324-145.951,151.952v1.391c-2.809,81.823,61.047,150.438,142.626,153.256
	c1.519,0.053,3.038,0.082,4.558,0.087c86.152,0,145.951-68.324,145.951-151.951V2746.256z M7166.546,2990.029l-235.495-310.087
	v310.087h-105.109V2502.33h98.637l227.789,300.348V2502.33h105.571v487.698H7166.546z M7790.729,2990.029l-44.386-109.442H7541.21
	l-44.387,109.442h-109.116l208.369-491.254h98.637l208.369,491.254H7790.729z M7644.315,2627.849l-64.576,158.135h129.152
	L7644.315,2627.849z M8023.911,2990.183V2502.33h106.959v390.158h242.43v97.54L8023.911,2990.183z"
    />
    <path
      d="M5035.693,944.787c-56.799-29.842-119.62-46.334-183.711-48.229
	c-127.918,0-200.354,92.748-219.312,123.664v994.255h-387.919V620.944h355.399l22.193,145.923
	c15.412-25.352,107.884-175.912,291.285-175.912c41.548,0.543,82.747,7.69,122.063,21.178V944.787z M5956.247,612.132
	c-39.315-13.484-80.515-20.632-122.063-21.178c-183.556,0-275.411,150.561-291.285,175.911l-22.193-145.923h-355.399v1393.688
	h387.919v-994.409c19.573-31.688,91.239-123.664,219.312-123.664c64.092,1.896,126.913,18.388,183.711,48.229L5956.247,612.132z
	 M4007.102,1020.994v993.637h-356.016l-22.193-145.305c-24.506,23.806-138.708,175.294-374.048,175.294
	c-221.932,0-375.126-170.038-375.126-398.97c0-493.108,579.797-562.515,739.772-578.746v-39.572
	c0-80.69-67.505-164.473-246.591-164.473c-189.259,0-335.056,104.96-361.256,123.663V699.159
	c151.215-72.705,317.02-109.72,484.705-108.205C3852.981,590.646,4007.102,754.035,4007.102,1020.994z M3619.183,1339.274
	c-123.296,0-348.464,42.51-348.464,237.589c0,98.931,67.504,170.037,152.115,170.037c110.041,0,196.348-79.145,196.348-257.375
	L3619.183,1339.274z M1490.333,207.752l348.772,750.638l-136.858,293.701L1120.447-0.003L677.2,207.288l348.464,749.556
	c-27.279,58.432-71.05,153.497-141.944,304.831c-159.359,341.775-282.655,437.46-501.042,437.46
	c-60.058-1.086-119.524-12.106-176.004-32.616L0,2110.78c104.694,43.726,216.728,67.071,330.124,68.789
	c258.458,0,448.333-168.492,613.395-523.716l203.437-437.46l369.887,796.703h539.418l-233.646-502.384l136.858-293.701
	l369.886,796.703h539.418L1933.58-0.003L1490.333,207.752z M9235.289,621.098V159.987l-387.918,181.477v279.635H8723.15v242.38
	h124.373v1151.309h387.918V863.323h196.656v-242.38L9235.289,621.098z M6101.428,2014.786h387.918V620.943h-387.918V2014.786z
	 M8411.521,159.832l-387.918,181.477v279.634h-124.374v242.381h124.374v1151.309h387.918V863.323h196.811v-242.38h-196.811V159.832z
	 M7808.606,1203.089v229.241c0,332.191-161.825,612.444-559.299,612.444s-559.298-278.243-559.298-612.444v-229.087
	c0-333.582,161.824-612.444,559.298-612.444s559.299,280.253,559.299,612.444V1203.089z M7417.605,1147.905
	c0-166.637-40.379-284.891-168.298-284.891s-168.298,118.254-168.298,284.891v340.074c0,166.637,39.609,284.891,168.298,284.891
	c128.689,0,168.298-118.254,168.298-284.891V1147.905z"
    />
  </svg>
);
