import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Video } from './Video';

const HeroImage = styled.div`
  border: 1px solid ${colors.navyBlue};
  margin: 0 16px 32px;
`;

const HeroText = styled(Flex)`
  padding: 40px 20px 0;
`;

export const HeroSmall = () => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <HeroText column justifyContent="center">
      <Heading marginBottom={16} variant={4}>
        {heading}
      </Heading>
      <Body marginBottom={24} variant={3}>
        {body}
      </Body>
    </HeroText>
    <HeroImage>
      <Video />
    </HeroImage>
  </ShowOn>
);
