import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BenefitCardSmall } from './BenefitCardSmall';
import { Tab } from './moreWaysToSaveUtils';

const TabContent = styled.div`
  width: 100%;
`;

interface TabContentProps extends Tab {}

export const TabContentSmall: FC<TabContentProps> = ({
  content: { heading, body },
  cardItems,
}) => (
  <TabContent>
    <Heading variant={4} marginBottom={8}>
      {heading}
    </Heading>
    <Body variant={3} marginBottom={24}>
      {body}
    </Body>
    {cardItems.map((cardItem, idx) => (
      <BenefitCardSmall {...cardItem} key={idx} />
    ))}
  </TabContent>
);
