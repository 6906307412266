import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface PartnerCardProps {
  heading: string;
  body: string;
  features: React.ReactNode[];
  additionalText?: string;
  image: React.ReactNode;
  button?: React.ReactNode;
}

const Card = styled(Flex)`
  background: ${colors.white};
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  padding: 40px 16px;
  margin-bottom: 32px;

  ${`@media ${device.lg}`} {
    padding: 56px 64px;
    margin-bottom: 64px;
  }

  ${`@media ${device.xl}`} {
    width: 65%;
  }
`;

const Features = styled(Flex)`
  gap: 40px;

  ${`@media ${device.lg}`} {
    flex-direction: row;

    > div:first-child {
      border-right: 1px solid #dadae2;
      padding-right: 20px;
    }
  }
`;

const StyledBody = styled(Body)`
  display: initial;
`;

export const PartnerCard: FC<PartnerCardProps> = ({
  heading,
  body,
  features,
  additionalText,
  image,
  button,
}) => (
  <Card column>
    <ResponsiveHeading variant={5} marginBottom={12}>
      {heading}
    </ResponsiveHeading>
    <Body medium variant={3} marginBottom={24}>
      {body}
    </Body>
    <Features column>
      <Flex column>
        {features.map((feature, idx) => (
          <li key={idx}>
            <StyledBody variant={3}>{feature}</StyledBody>
          </li>
        ))}
      </Flex>
      <Flex column justifyContent="center">
        {additionalText && (
          <Body marginBottom={16} variant={3}>
            {additionalText}
          </Body>
        )}
        {image}
      </Flex>
    </Features>
    {button}
  </Card>
);
