import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Video } from './Video';

const HeroWrapper = styled(ShowOn)`
  padding: 100px 4%;
`;

const HeroText = styled(Flex)`
  flex-basis: 58%;
  max-width: 722px;
  margin: auto;
  padding: 0 40px;
`;

const HeroVideo = styled(Flex)`
  position: relative;
  flex-basis: 42%;
`;

export const HeroLarge = () => (
  <HeroWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Flex>
      <HeroVideo>
        <Video />
      </HeroVideo>
      <HeroText column justifyContent="center">
        <ResponsiveHeading variant={2} marginBottom={24}>
          {heading}
        </ResponsiveHeading>
        <ResponsiveBody variant={1} marginBottom={24}>
          {body}
        </ResponsiveBody>
      </HeroText>
    </Flex>
  </HeroWrapper>
);
