import { ButtonColor } from 'component-library/components/buttons/PrimaryButton';
import { FlexProps } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';

import { moreWaysToSave } from './content';
import { HighlightButton, HighlightCard } from './shared';

export const MoreWaysToSave: FC<FlexProps> = (props) => (
  <HighlightCard backgroundColor={colors.teal} {...props}>
    <Body variant={3} marginBottom={12}>
      {moreWaysToSave.heading}
    </Body>
    <Body variant={1} marginBottom={32}>
      {moreWaysToSave.body}
    </Body>
    <LinkWrapper to="#more-ways-to-save">
      <HighlightButton withCaret buttonColor={ButtonColor.NavyBlue}>
        {moreWaysToSave.buttonCta}
      </HighlightButton>
    </LinkWrapper>
  </HighlightCard>
);
