import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import {
  Hero,
  Highlights,
  Partners,
  SpendingCard,
  WaysToSave,
} from 'page-components/benefits';
import { BottomCta, Featured } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface IncomeProps {}

const Benefits: FC<IncomeProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background="linear-gradient(180deg, rgba(248, 236, 193, 0.8) 0.34%, rgba(255, 232, 216, 0.4) 10.42%, rgba(249, 241, 187, 0.4) 31.25%, rgba(220, 241, 232, 0.7) 41.66%, rgba(220, 241, 232, 0.2) 78.12%, #FFEDE0 90.62%, #DBF0E7 100%)">
      <Hero />
      <Featured />
      <Highlights />
      <Partners />
      <SpendingCard />
      <WaysToSave />
      <BottomCta
        heading="Spend retirement with greater peace of mind."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default Benefits;

export const Head = () => (
  <>
    <SEO
      title="Benefits | Retirable"
      description="Retirable benefits enable you to live your best golden years. From planning, investing to spending, we can handle it all and provide a clear retirement picture for you."
      imagePath="benefits-featured.png"
    />
    <script
      type="text/javascript"
      async
      src="https://play.vidyard.com/embed/v4.js"
    ></script>
  </>
);
