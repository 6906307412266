import React from 'react';

export const Video = () => (
  <img
    alt="benefits description"
    className="vidyard-player-embed"
    src="https://play.vidyard.com/J8PVPxLaxvxXWd3NfVQY62.jpg"
    data-uuid="J8PVPxLaxvxXWd3NfVQY62"
    data-v="4"
    data-type="inline"
    data-autoplay="0"
  />
);
