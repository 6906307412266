import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { CardItem } from './moreWaysToSaveUtils';

const BenefitCard = styled(Flex)`
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  padding: 32px 24px;
  margin-bottom: 40px;
`;

const IconWrapper = styled(Flex)`
  height: 80px;
  width: 200px;
`;

const Benefit = styled(Flex)`
  flex-basis: 20%;
`;

const Eligibility = styled(Flex)`
  border-left: 1px solid #dadae2;
  padding-left: 40px;
  margin-left: 40px;
  flex-basis: 60%;
`;

const IconAndText = styled(Flex)`
  margin-right: 64px;
  flex-basis: 20%;
`;

interface BenefitCardProps extends CardItem {}

export const BenefitCardLarge: FC<BenefitCardProps> = ({
  name,
  benefit,
  eligibility,
  icon,
}) => (
  <BenefitCard alignItems="center">
    <IconAndText column>
      <Body variant={1} marginBottom={12}>
        {name}
      </Body>
      <IconWrapper>{icon}</IconWrapper>
    </IconAndText>
    <Benefit column justifyContent="center">
      <Body medium variant={2} marginBottom={12}>
        Benefit
      </Body>
      <Body variant={2}>{benefit}</Body>
    </Benefit>
    <Eligibility column>
      <Body medium variant={2} marginBottom={12}>
        Eligibility
      </Body>
      <Body variant={2}>{eligibility}</Body>
    </Eligibility>
  </BenefitCard>
);
