import React, { FC, SVGProps } from 'react';

export const Verizon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 658.2 146"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <polygon
        fill="#D52B1E"
        points="643.7,0 607.8,76.8 594.3,47.7 579.7,47.7 601.9,95.3 613.7,95.3 658.2,0 	"
      />
      <path d="M489.7,142.6h28.9V89.7c0-12.1,7-20.6,17.4-20.6c10,0,15.2,7,15.2,17.1v56.4h28.9V80.7c0-21-12.6-35.8-33-35.8 c-13,0-22.1,5.6-28.9,15.8h-0.6v-13h-28L489.7,142.6L489.7,142.6z M432.9,45.1c-30.2,0-50.4,21.7-50.4,50.3 c0,28.4,20.2,50.3,50.4,50.3s50.4-21.9,50.4-50.3C483.4,66.8,463.1,45.1,432.9,45.1z M432.7,124.3c-13.7,0-21-11.5-21-28.9	c0-17.6,7.2-28.9,21-28.9c13.7,0,21.3,11.3,21.3,28.9C454.1,112.8,446.5,124.3,432.7,124.3z M300.1,142.6h81.2v-22.8h-46v-0.6	l44-49.3V47.6h-79.2v22.9h44.5v0.6l-44.5,49.7L300.1,142.6L300.1,142.6z M263,142.6h29.1V47.7H263V142.6z M195.5,142.6h29V99	c0-19.8,11.9-28.6,30-26.1h0.6v-25c-1.5-0.6-3.2-0.7-5.9-0.7c-11.3,0-18.9,5.2-25.4,16.3h-0.6V47.7h-27.7V142.6z M142.3,124.4	c-12.8,0-20.6-8.3-22.1-21.1h68.4c0.2-20.4-5.2-36.7-16.5-46.9c-8-7.4-18.5-11.5-31.9-11.5c-28.6,0-48.4,21.7-48.4,50.1	c0,28.6,18.9,50.4,50.3,50.4c11.9,0,21.3-3.2,29.1-8.5c8.3-5.7,14.3-14.1,15.9-22.4h-27.8C156.6,120.7,150.8,124.4,142.3,124.4z M140.8,65.6c10.2,0,17.2,7.6,18.4,18.7h-38.8C122.7,73.1,128.8,65.6,140.8,65.6z M34,142.6h30.4l33-94.9H68.3l-18.5,61h-0.4	l-18.5-61H1L34,142.6z M263,13.9h29.1v25.8H263V13.9z" />
    </g>
  </svg>
);
