import React, { FC, SVGProps } from 'react';

export const GoodRX: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="271"
    height="80"
    viewBox="0 0 271 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.7875 56.7001C42.45 56.7001 46.1125 55.8001 49.475 53.3376V44.9625H39.2375V37.5H58.1501V57.2251C53.7376 61.4876 47.5375 64.9251 38.7875 64.9251C23.0125 64.9251 13 53.7876 13 39.9625C13 26.1375 23.0125 15 38.0375 15C45.3625 15 51.7876 17.7625 56.2001 22.775L49.85 29.05C47.0125 25.5375 42.9 23.45 38.0375 23.45C28.85 23.45 21.9625 31.075 21.9625 39.9625C21.9625 49.075 28.8375 56.7001 38.7875 56.7001Z"
      fill="#201F1B"
    />
    <path
      d="M81.7491 64.9996C71.2865 64.9996 62.999 56.6996 62.999 46.4621C62.999 36.2246 71.299 27.9246 81.7615 27.9246C92.2241 27.9246 100.524 36.2246 100.524 46.4621C100.512 56.6996 92.2116 64.9996 81.7491 64.9996ZM81.7491 56.9246C87.7241 56.9246 92.1366 51.9871 92.1366 46.4621C92.1366 40.9371 87.7366 35.9996 81.7491 35.9996C75.774 35.9996 71.3615 40.9371 71.3615 46.4621C71.3615 51.9871 75.774 56.9246 81.7491 56.9246Z"
      fill="#201F1B"
    />
    <path
      d="M122.263 64.9996C111.8 64.9996 103.5 56.6996 103.5 46.4621C103.5 36.2246 111.8 27.9246 122.263 27.9246C132.725 27.9246 141.025 36.2246 141.025 46.4621C141.013 56.6996 132.725 64.9996 122.263 64.9996ZM122.263 56.9246C128.238 56.9246 132.65 51.9871 132.65 46.4621C132.65 40.9371 128.238 35.9996 122.263 35.9996C116.288 35.9996 111.875 40.9371 111.875 46.4621C111.875 51.9871 116.275 56.9246 122.263 56.9246Z"
      fill="#201F1B"
    />
    <path
      d="M173.151 15.7495H181.526V64.2496H173.151V60.5871C170.539 63.3496 166.651 64.9996 162.238 64.9996C152.076 64.9996 144.001 56.3996 144.001 46.4621C144.001 36.5245 152.076 27.9245 162.238 27.9245C166.651 27.9245 170.539 29.562 173.151 32.337V15.7495ZM162.763 35.9995C156.788 35.9995 152.376 40.937 152.376 46.4621C152.376 51.9871 156.788 56.9246 162.763 56.9246C168.739 56.9246 173.151 51.9871 173.151 46.4621C173.151 40.937 168.739 35.9995 162.763 35.9995Z"
      fill="#201F1B"
    />
    <path
      d="M216.501 64.2496L205.214 45.0371H195.651V64.2496H189.751V15.7495H204.851C216.514 15.7495 221.739 22.1745 221.739 30.2495C221.739 36.9745 218.301 42.437 211.501 44.2996L223.239 64.2496H216.501ZM195.638 40.187H205.126C212.301 40.187 215.814 36.4495 215.814 30.6995C215.814 24.9495 212.301 21.212 205.126 21.212H195.638V40.187Z"
      fill="#201F1B"
    />
    <path
      d="M237.412 45.8625L225.012 28.75H231.662L240.775 41.3125L249.9 28.75H256.55L244.137 45.9375L257.525 64.25H250.8L240.7 50.5L230.762 64.25H224.037L237.412 45.8625Z"
      fill="#201F1B"
    />
  </svg>
);
