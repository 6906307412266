import { Flex } from 'component-library/components/layout/Flex';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import React, { FC, ReactElement, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { Tab } from './Tab';

const Card = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  padding: 34px 16px;

  ${`@media ${device.lg}`} {
    padding: 64px;
  }
`;

const DesktopTabWrapper = styled(Flex)`
  display: none;

  ${`@media ${device.lg}`} {
    display: flex;

    > div:first-child {
      margin-left: 64px;
    }
  }
`;

const MobileTabWrapper = styled(Flex)`
  width: 100%;

  ${`@media ${device.lg}`} {
    display: none;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

interface TabItemProps {
  tabContent: ReactElement;
  tabLabel: string;
}

interface TabbedCardProps {
  tabs: TabItemProps[];
}

export const TabbedCard: FC<TabbedCardProps> = ({ tabs }) => {
  const tabsWithIndex = tabs.map((tab, index) => ({
    value: `${index}`,
    label: tab.tabLabel,
    ...tab,
  }));
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedTab = tabsWithIndex[selectedTabIndex];

  const handleSelect = (tab: any) => {
    setSelectedTabIndex(parseInt((tab?.value as any) || '0'));
  };

  return (
    <>
      <DesktopTabWrapper>
        {tabs.map(({ tabLabel }, index) => (
          <Tab
            key={index}
            tabLabel={tabLabel}
            onClick={() => setSelectedTabIndex(index)}
            index={index}
            selected={index === selectedTabIndex}
          />
        ))}
      </DesktopTabWrapper>
      <Card>
        <MobileTabWrapper marginBottom={20}>
          <StyledSelect
            value={selectedTab}
            options={tabsWithIndex}
            onChange={handleSelect}
          />
        </MobileTabWrapper>
        <>{selectedTab.tabContent}</>
      </Card>
    </>
  );
};
