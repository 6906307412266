import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  Albertsons,
  ATT,
  BestWestern,
  ChoiceHotel,
  Costco,
  GoodRX,
  HarrisTeeter,
  IghHotelsAndResorts,
  Kroger,
  Marriott,
  Publix,
  Safeway,
  TMobile,
  Verizon,
  Walgreens,
  WyndhamHotels,
} from '../logos';
import { TabContentLarge } from './TabContentLarge';
import { TabContentSmall } from './TabContentSmall';

export interface CardItem {
  icon: React.ReactNode;
  name: string;
  benefit: string;
  eligibility: string;
}

const styledSvg = (icon: FC) => styled(icon)`
  max-height: 56px;
  max-width: 220px;
`;

const StyledCostco = styledSvg(Costco);
const StyledGoodRx = styledSvg(GoodRX);
const StyledWalgreens = styledSvg(Walgreens);

const PHARMACY_CARD_ITEMS: CardItem[] = [
  {
    icon: <StyledCostco />,
    name: 'Costco Wholesale',
    benefit: 'Save up to 80%',
    eligibility: 'Costco membership and Costco member prescription program',
  },
  {
    icon: <StyledGoodRx />,
    name: 'GoodRx',
    benefit: 'Save up to 80%',
    eligibility: 'Generally free, sometimes fee of $10',
  },
  {
    icon: <StyledWalgreens />,
    name: 'Walgreens',
    benefit: 'Save up to 80%',
    eligibility: 'Enroll in the Walgreens Prescription Savings Club',
  },
];

const StyledKroger = styledSvg(Kroger);
const StyledHarrisTeeter = styledSvg(HarrisTeeter);
const StyledAlbertsons = styledSvg(Albertsons);
const StyledPublix = styledSvg(Publix);
const StyledSafeway = styledSvg(Safeway);

const GROCERY_CARD_ITEMS: CardItem[] = [
  {
    icon: <StyledKroger />,
    name: 'Kroger',
    benefit: 'Up to 10%',
    eligibility: '55 & up, Tuesdays only at participating locations',
  },
  {
    icon: <StyledHarrisTeeter />,
    name: 'Haris Teeter',
    benefit: 'Up to 5%',
    eligibility: '60+, Wednesdays only',
  },
  {
    icon: <StyledAlbertsons />,
    name: 'Albertsons',
    benefit: 'Up to 10%',
    eligibility: '55+, first Wednesday of the month at participating locations',
  },
  {
    icon: <StyledPublix />,
    name: 'Publix',
    benefit: 'Weekly Savings',
    eligibility: 'Publix Club membership',
  },
  {
    icon: <StyledSafeway />,
    name: 'Safeway',
    benefit: 'Weekly savings',
    eligibility: 'Safeway membership',
  },
];

const StyledATT = styledSvg(ATT);
const StyledVerizon = styledSvg(Verizon);
const StyledTMobile = styledSvg(TMobile);

const MOBILE_PHONE_CARD_ITEMS: CardItem[] = [
  {
    icon: <StyledATT />,
    name: 'AT&T',
    benefit: 'Senior Plans starting at $60 per month',
    eligibility: 'Available to those 55+ in qualifying locations',
  },
  {
    icon: <StyledVerizon />,
    name: 'Verizon',
    benefit:
      'Senior plans starting at $60 per month for single lines, $80 per month for two lines.',
    eligibility: 'Available to those 55+ in qualifying locations',
  },
  {
    icon: <StyledTMobile />,
    name: 'TMobile',
    benefit: 'Unlimited talk, text and smartphone data',
    eligibility: 'Available to those 55+ in qualifying locations',
  },
];

const StyledBestWestern = styledSvg(BestWestern);
const StyledChoiceHotel = styledSvg(ChoiceHotel);
const StyledIghHotelsAndResorts = styledSvg(IghHotelsAndResorts);
const StyledMarriott = styledSvg(Marriott);
const StyledWyndhamHotels = styledSvg(WyndhamHotels);

const HOTEL_CARD_ITEMS: CardItem[] = [
  {
    icon: <StyledBestWestern />,
    name: 'Best Western',
    benefit: 'Up to 15% discount',
    eligibility: '55+ years or AARP membership at participating locations',
  },
  {
    icon: <StyledChoiceHotel />,
    name: 'Choice Hotel',
    benefit: '10% - 30% discount ',
    eligibility: '60+ years or AARP membership',
  },
  {
    icon: <StyledIghHotelsAndResorts />,
    name: 'IGH Hotels and Resorts',
    benefit: 'Daily senior discounts',
    eligibility: '62+ years',
  },
  {
    icon: <StyledMarriott />,
    name: 'Marriott',
    benefit: '15% discount',
    eligibility: '62+ years. Select senior discount at time of reservation',
  },
  {
    icon: <StyledWyndhamHotels />,
    name: 'Wyndham Hotels',
    benefit: '10% discount',
    eligibility: 'AARP membership',
  },
];

export interface Tab {
  content: {
    heading: string;
    body: string;
  };
  tabLabel: string;
  cardItems: CardItem[];
}

const TABS: Tab[] = [
  {
    content: {
      heading: 'Save on groceries',
      body:
        'Maximizing your spending on groceries is an easy way to save funds that might be utilized elsewhere. Many grocery stores offer discounts for seniors.',
    },
    cardItems: GROCERY_CARD_ITEMS,
    tabLabel: 'Groceries',
  },
  {
    content: {
      heading: 'Save on prescriptions',
      body:
        'Taking care of your health is more important now than ever. Most national pharmacies offer prescription discount programs that can provide big savings.',
    },
    cardItems: PHARMACY_CARD_ITEMS,
    tabLabel: 'Pharmacy',
  },
  {
    content: {
      heading: 'Save money on your mobile phone plan',
      body:
        "Communication is more important now than ever. Ensure that you're taking advantage of any discounts that may be available.",
    },
    cardItems: MOBILE_PHONE_CARD_ITEMS,
    tabLabel: 'Cell Phones',
  },
  {
    content: {
      heading: 'Travel more, spend less',
      body:
        "For many, travel is an important retirement goal. The more you save on accommodations the more you'll be able to travel and see the parts of the world you'd like to see.",
    },
    cardItems: HOTEL_CARD_ITEMS,
    tabLabel: 'Hotels',
  },
];

export const MORE_WAY_TO_SAVE_TABS = TABS.map((tab) => ({
  tabContent: (
    <>
      <ShowOn screens={[Screen.Sm, Screen.Md]}>
        <TabContentSmall {...tab} />
      </ShowOn>
      <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
        <TabContentLarge {...tab} />
      </ShowOn>
    </>
  ),
  ...tab,
}));
