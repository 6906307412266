import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { device } from 'component-library/styles/device';
import React from 'react';
import styled from 'styled-components';

import { body, heading, partners } from './content';
import { PartnerCard } from './PartnerCard';

const PartnersWrapper = styled.div`
  padding: 36px 16px 32px;

  ${`@media ${device.lg}`} {
    padding: 0 40px 60px;
    max-width: 1680px;
    margin: 0 auto;
  }
`;

const PartnerCards = styled(Flex)`
  ${`@media ${device.lg}`} {
    > div:nth-child(odd) {
      align-self: flex-end;
    }
  }
`;

export const Partners = () => (
  <PartnersWrapper>
    <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
      <ResponsiveHeading marginBottom={16} variant={2}>
        {heading}
      </ResponsiveHeading>
      <Body marginBottom={64} variant={1}>
        {body}
      </Body>
    </ShowOn>
    <ShowOn screens={[Screen.Sm, Screen.Md]}>
      <Heading marginBottom={16} variant={4}>
        {heading}
      </Heading>
      <Body marginBottom={40} variant={3}>
        {body}
      </Body>
    </ShowOn>
    <PartnerCards column>
      {partners.map((partner, idx) => (
        <PartnerCard key={idx} {...partner} />
      ))}
    </PartnerCards>
  </PartnersWrapper>
);
