import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { MoreWaysToSave } from './MoreWaysToSave';
import { SpendConfidently } from './SpendConfidently';

const HighlightsWrapper = styled(ShowOn)`
  padding: 32px 0;
`;

export const HighlightsSmall = () => (
  <HighlightsWrapper screens={[Screen.Sm, Screen.Md]}>
    <SpendConfidently marginBottom={16} />
    <MoreWaysToSave />
  </HighlightsWrapper>
);
