import React, { FC, SVGProps } from 'react';

export const Safeway: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 214 40"
    {...props}
  >
    <title>Safeway_RGB</title>
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M11.31,18.19,9.28,17c-2.45-1.4-3.77-2.49-3.77-4.16,0-1.85,1.54-3,4-3,1.66,0,3.72.7,6.49,2.17l.35.19V7.35s-.05,0-.05,0a15.93,15.93,0,0,0-7.16-1.7C4,5.61.45,8.62.45,13.09c0,3.7,2.4,5.86,6,8L8.56,22.3c3,1.74,4.06,3,4.06,4.91,0,2.19-2,3.72-4.81,3.72-2.26,0-4.71-.88-7.71-2.79L0,28.07v5.56s.38.22.38.22a18,18,0,0,0,7.67,1.66c5.9,0,9.86-3.38,9.86-8.41,0-3.64-1.85-6.14-6.61-8.91"
      transform="translate(0 -0.1)"
    />
    <polygon
      className="cls-1"
      fill="#2c2a29"
      points="47.39 6.17 47.39 34.76 52.5 34.76 52.5 22.22 63.77 22.22 63.77 17.75 52.5 17.75 52.5 10.7 64.35 10.7 64.35 6.17 47.39 6.17"
    />
    <polygon
      className="cls-1"
      fill="#2c2a29"
      points="72.66 22.02 83.49 22.02 83.49 17.55 72.66 17.55 72.66 10.7 84.41 10.7 84.41 6.17 67.65 6.17 67.65 34.76 84.61 34.76 84.61 30.18 72.66 30.18 72.66 22.02"
    />
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M164.33,6.28h-6.12s-2.79,4.34-6.48,10.13a6.93,6.93,0,0,0-.6,1.09s-.06,0-.07,0a7.57,7.57,0,0,0-.62-1.08c-4-6.2-6.49-10.14-6.49-10.14h-6.13s10.66,15.55,10.68,15.59v13h5.17v-13c.57-.81,10.66-15.55,10.66-15.55"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M131.12,5.36h-.49l-13.52,29.5h5.51l2.13-5H137l2.13,5h5.52L131.12,5.36m-4.6,20.37s2.4-5.73,3.93-9.26a7.93,7.93,0,0,0,.4-1.4h.06a12.08,12.08,0,0,0,.39,1.4l3.93,9.26Z"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M104.18,18.82a8.44,8.44,0,0,0,.4-1.43h.07a8.21,8.21,0,0,0,.42,1.43c.93,2.35,6.83,17,6.83,17h.47L122.68,6.28h-5.39s-3.86,12.06-5.09,15.92a10.87,10.87,0,0,0-.37,1.4h-.05a10.35,10.35,0,0,0-.41-1.4c-1-2.53-6.54-17-6.54-17h-.45L97.88,22.16a10.28,10.28,0,0,0-.4,1.45h-.06c0-.19-.24-1-.38-1.4-1.27-4-5.11-15.92-5.11-15.92H86.54l10.3,29.57h.48l6.85-17"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M32,5.36h-.49L18,34.86h5.5l2.14-5H37.9l2.15,5h5.51L32,5.36M27.45,25.74s2.39-5.73,3.91-9.26a8.5,8.5,0,0,0,.41-1.4h.05a10.37,10.37,0,0,0,.4,1.4l3.93,9.26Z"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-2"
      fill="#e41720"
      d="M193.15,32.85c0-7.93-11.81-8.67-11.81-19.9,0-8.1,6.33-12.13,12.62-12.79-.58,0-1.18-.05-2-.05-1.52,0-2.28.07-3.56.12-11.77.58-18,6.84-18.43,16.48,0,.63-.1,1.69-.1,3.41s.06,2.76.1,3.38c.38,9.38,6.33,15.56,17.49,16.42,1.81-.34,5.73-1.69,5.73-7.07"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-2"
      fill="#e41720"
      d="M213.92,16.7c-.37-9.13-6-15.23-16.62-16.33-1.88.38-5.58,1.79-5.58,7,0,7.92,11.81,8.65,11.81,19.89,0,7-5.33,12.16-13.35,12.76l1.76,0c1.53,0,2.27,0,3.56-.11,11.76-.57,18-6.83,18.43-16.47,0-.62.08-1.7.08-3.38s-.06-2.78-.08-3.42"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-3"
      fill="#fff"
      d="M203.52,27.31c0-11.24-11.81-12-11.81-19.9,0-5.25,3.71-6.67,5.58-7-.6-.06-1.19-.11-1.8-.15L194,.16c-6.29.66-12.62,4.68-12.62,12.79,0,11.23,11.81,12,11.81,19.9,0,5.39-3.91,6.73-5.72,7.07l.94.06,1.8.09c8-.6,13.35-5.74,13.35-12.76"
      transform="translate(0 -0.1)"
    />
    <path
      className="cls-1"
      fill="#2c2a29"
      d="M210.75,38.52a1.58,1.58,0,1,1,1.59,1.58,1.58,1.58,0,0,1-1.59-1.58m3,0a1.39,1.39,0,1,0-1.39,1.39,1.39,1.39,0,0,0,1.39-1.39m-.6.88h-.26l-.51-.81H212v.81h-.22V37.64h.77a.85.85,0,0,1,.43.09.44.44,0,0,1,.2.38c0,.34-.25.47-.56.48Zm-.83-1c.25,0,.63,0,.63-.32s-.24-.31-.46-.31H212v.63Z"
      transform="translate(0 -0.1)"
    />
  </svg>
);
