import { PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { device } from 'component-library/styles/device';
import styled from 'styled-components';

interface HighlightCardProps extends FlexProps {
  backgroundColor: string;
  padding?: string;
}

export const HighlightCard = styled(Flex).attrs({ column: true })<
  HighlightCardProps
>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 24px;
  padding: ${({ padding }) => padding || '40px 32px'};

  ${`@media ${device.lg}`} {
    padding: ${({ padding }) => padding || '56px 64px'};
  }
`;

export const HighlightButton = styled(PrimaryButton)`
  width: 100%;

  ${`@media ${device.lg}`} {
    width: fit-content;
  }
`;
