export const spendConfidently = {
  heading: 'Spend confidently',
  body: 'Introducing our high-yield debit card.',
  buttonCta: 'Learn More',
};

export const moreWaysToSave = {
  heading: 'More ways to save',
  body: 'Extend your runway without lifting a finger.',
  buttonCta: 'See Discounts',
};
