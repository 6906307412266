import React, { FC, SVGProps } from 'react';

export const IghHotelsAndResorts: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="243px"
    height="40px"
    viewBox="0 0 243 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>IHG-Only-logo-Black</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <polygon
        id="path-1"
        points="0.3181 0.284666667 34.8057667 0.284666667 34.8057667 39.6665667 0.3181 39.6665667"
      ></polygon>
      <polygon
        id="path-3"
        points="0.145733333 0.0745 13.6186667 0.0745 13.6186667 14.6358333 0.145733333 14.6358333"
      ></polygon>
    </defs>
    <g
      id="Header-Phase-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Header_Large_1008px_alt"
        transform="translate(-15.000000, -62.000000)"
      >
        <g id="Primary" transform="translate(0.000000, 40.000000)">
          <g
            id="IHG-Logo-Secondary-Horizontal"
            transform="translate(15.000000, 22.000000)"
          >
            <g id="Group-38">
              <polygon
                id="Fill-1"
                fill="#000000"
                points="54.8537667 0.966766667 49.1001 0.966766667 49.1001 17.7344333 28.3147667 17.7344333 28.3147667 0.966766667 22.6081 0.966766667 22.6081 39.0817667 28.3147667 39.0817667 28.3147667 21.6681 49.1001 21.6681 49.1001 39.0817667 54.8537667 39.0817667"
              ></polygon>
              <polygon
                id="Fill-2"
                fill="#000000"
                points="0 39.082 5.69633333 39.082 5.69633333 0.967 0 0.967"
              ></polygon>
              <g id="Group-37" transform="translate(66.666667, 0.048667)">
                <g id="Group-5">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <g id="Clip-4"></g>
                  <path
                    d="M29.0477667,20.6909 L29.0477667,35.4639 C27.4361,36.5579 24.7301,37.4209 21.6777667,37.4209 C12.7547667,37.4209 6.6511,29.5329 6.6511,19.5729 C6.6511,10.1875667 12.0051,2.4729 20.9301,2.4729 C27.0904333,2.4729 30.6031,5.35156667 33.1934333,8.5759 L33.4814333,8.5759 L33.4814333,1.95456667 C31.6387667,1.49423333 26.5144333,0.284566667 21.1604333,0.284566667 C8.2061,0.284566667 0.3181,9.72723333 0.3181,20.0912333 C0.3181,31.4329 8.8391,39.6665667 21.2757667,39.6665667 C27.4361,39.6665667 32.2144333,37.3065667 34.8057667,36.5579 L34.8057667,20.6909 L29.0477667,20.6909 Z"
                    id="Fill-3"
                    fill="#000000"
                    mask="url(#mask-2)"
                  ></path>
                </g>
                <path
                  d="M38.5281,3.47883333 C38.7854333,3.47883333 38.9197667,3.3655 38.9197667,3.14916667 L38.9197667,3.13916667 C38.9197667,2.90216667 38.7751,2.81916667 38.5281,2.81916667 L38.2394333,2.81916667 L38.2394333,3.47883333 L38.5281,3.47883333 Z M37.7447667,2.43816667 L38.5384333,2.43816667 C39.0637667,2.43816667 39.4037667,2.6545 39.4037667,3.11816667 L39.4037667,3.12883333 C39.4037667,3.46883333 39.2081,3.65416667 38.9401,3.73683333 L39.5374333,4.69483333 L39.0227667,4.69483333 L38.4764333,3.81916667 L38.2394333,3.81916667 L38.2394333,4.69483333 L37.7447667,4.69483333 L37.7447667,2.43816667 Z M40.4034333,3.59216667 C40.4034333,2.46916667 39.6201,1.68616667 38.5281,1.68616667 C37.4357667,1.68616667 36.6527667,2.4795 36.6527667,3.60283333 C36.6527667,4.73616667 37.4771,5.49883333 38.5281,5.49883333 C39.5891,5.49883333 40.4034333,4.7155 40.4034333,3.59216667 L40.4034333,3.59216667 Z M36.2401,3.60283333 C36.2401,2.33516667 37.2501,1.31516667 38.5281,1.31516667 C39.8264333,1.31516667 40.8154333,2.32483333 40.8154333,3.59216667 C40.8154333,4.8805 39.8057667,5.86983333 38.5281,5.86983333 C37.2604333,5.86983333 36.2401,4.89083333 36.2401,3.60283333 L36.2401,3.60283333 Z"
                  id="Fill-6"
                  fill="#000000"
                ></path>
                <polygon
                  id="Fill-8"
                  fill="#000000"
                  points="55.3568667 0.9531 57.2048667 0.9531 57.2048667 7.37243333 68.0962 7.37243333 68.0962 0.9531 69.9445333 0.9531 69.9445333 15.1631 68.0962 15.1631 68.0962 8.8631 57.2048667 8.8631 57.2048667 15.1631 55.3568667 15.1631"
                ></polygon>
                <path
                  d="M90.6325667,8.08786667 L90.6325667,7.92886667 C90.6325667,4.76886667 87.9099,2.1852 83.2195667,2.1852 C78.4892333,2.1852 75.8062333,4.60986667 75.8062333,7.96886667 L75.8062333,8.12786667 C75.8062333,11.5462 78.8672333,13.8912 83.2792333,13.8912 C87.8105667,13.8912 90.6325667,11.5062 90.6325667,8.08786667 M73.8785667,8.14753333 L73.8785667,7.98853333 C73.8785667,3.9542 77.5555667,0.7742 83.2195667,0.7742 C88.8835667,0.7742 92.5605667,3.9542 92.5605667,7.94886667 L92.5605667,8.10786667 C92.5605667,12.1225333 88.8835667,15.3422 83.2792333,15.3422 C77.5555667,15.3422 73.8785667,12.1225333 73.8785667,8.14753333"
                  id="Fill-10"
                  fill="#000000"
                ></path>
                <polygon
                  id="Fill-12"
                  fill="#000000"
                  points="99.9529 2.38393333 94.1099 2.38393333 94.1099 0.953266667 107.644233 0.953266667 107.644233 2.38393333 101.8009 2.38393333 101.8009 15.1632667 99.9529 15.1632667"
                ></polygon>
                <polygon
                  id="Fill-14"
                  fill="#000000"
                  points="111.141367 0.9531 123.1057 0.9531 123.1057 2.38376667 112.989367 2.38376667 112.989367 7.17376667 121.5157 7.17376667 121.5157 8.60476667 112.989367 8.60476667 112.989367 13.7321 123.5827 13.7321 123.5827 15.1631 111.141367 15.1631"
                ></polygon>
                <polygon
                  id="Fill-16"
                  fill="#000000"
                  points="127.2387 0.9531 129.0867 0.9531 129.0867 13.7321 138.249033 13.7321 138.249033 15.1631 127.2387 15.1631"
                ></polygon>
                <path
                  d="M140.494233,10.8305333 L142.302567,10.8305333 C142.6009,12.5795333 143.932567,13.9308667 147.728567,13.9308667 C150.769233,13.9308667 152.478567,12.8975333 152.478567,11.1685333 C152.478567,9.49886667 151.226233,8.96253333 147.3309,8.5052 C143.4159,8.0482 141.229567,7.1142 141.229567,4.5702 C141.229567,2.38386667 143.515233,0.7742 147.251567,0.7742 C150.9479,0.7742 153.313233,2.08586667 153.6909,4.68953333 L152.001567,4.68953333 C151.564233,2.8412 150.0139,2.1852 147.251567,2.1852 C144.250567,2.1852 142.958567,3.09953333 142.958567,4.53053333 C142.958567,5.80253333 143.892567,6.53786667 147.867567,6.99486667 C152.041233,7.47186667 154.267233,8.30653333 154.267233,11.1088667 C154.267233,13.5732 151.842233,15.3422 147.728567,15.3422 C142.958567,15.3422 140.8519,13.3348667 140.494233,10.8305333"
                  id="Fill-18"
                  fill="#000000"
                ></path>
                <path
                  d="M62.2333333,31.8430667 C64.5983333,31.8430667 66.089,30.8694 66.089,29.0607333 L66.089,28.9814 C66.089,26.9540667 64.479,26.1987333 62.2333333,26.1987333 L57.205,26.1987333 L57.205,31.8430667 L62.2333333,31.8430667 Z M55.357,24.7877333 L62.114,24.7877333 C65.274,24.7877333 67.9373333,25.9604 67.9373333,28.9614 L67.9373333,29.0407333 C67.9373333,31.5054 65.8503333,32.7374 63.863,33.0950667 L69.07,38.9977333 L66.8443333,38.9977333 L61.7563333,33.2344 L57.205,33.2344 L57.205,38.9977333 L55.357,38.9977333 L55.357,24.7877333 Z"
                  id="Fill-20"
                  fill="#000000"
                ></path>
                <polygon
                  id="Fill-22"
                  fill="#000000"
                  points="73.1234667 24.7877333 85.0878 24.7877333 85.0878 26.2187333 74.9718 26.2187333 74.9718 31.0084 83.4978 31.0084 83.4978 32.4394 74.9718 32.4394 74.9718 37.5670667 85.5648 37.5670667 85.5648 38.9977333 73.1234667 38.9977333"
                ></polygon>
                <path
                  d="M88.7040333,34.6653333 L90.5127,34.6653333 C90.8107,36.4143333 92.1423667,37.7656667 95.9383667,37.7656667 C98.9790333,37.7656667 100.688367,36.7323333 100.688367,35.003 C100.688367,33.3336667 99.4360333,32.797 95.5407,32.34 C91.6257,31.883 89.4393667,30.949 89.4393667,28.405 C89.4393667,26.2186667 91.7250333,24.609 95.4613667,24.609 C99.1577,24.609 101.523033,25.9206667 101.9007,28.524 L100.211367,28.524 C99.7740333,26.676 98.2237,26.02 95.4613667,26.02 C92.4603667,26.02 91.1687,26.9343333 91.1687,28.3653333 C91.1687,29.637 92.1027,30.3723333 96.0773667,30.8296667 C100.251033,31.3063333 102.477033,32.1413333 102.477033,34.9433333 C102.477033,37.408 100.052033,39.1766667 95.9383667,39.1766667 C91.1687,39.1766667 89.0617,37.1693333 88.7040333,34.6653333"
                  id="Fill-24"
                  fill="#000000"
                ></path>
                <path
                  d="M122.807533,31.9226667 L122.807533,31.7636667 C122.807533,28.6036667 120.084867,26.02 115.394533,26.02 C110.664533,26.02 107.9812,28.4446667 107.9812,31.8033333 L107.9812,31.9623333 C107.9812,35.3806667 111.0422,37.726 115.4542,37.726 C119.985533,37.726 122.807533,35.341 122.807533,31.9226667 M106.053533,31.9823333 L106.053533,31.8233333 C106.053533,27.7886667 109.7302,24.609 115.394533,24.609 C121.058533,24.609 124.735533,27.7886667 124.735533,31.7836667 L124.735533,31.9426667 C124.735533,35.9573333 121.058533,39.1766667 115.4542,39.1766667 C109.7302,39.1766667 106.053533,35.9573333 106.053533,31.9823333"
                  id="Fill-26"
                  fill="#000000"
                ></path>
                <path
                  d="M136.2216,31.8430667 C138.5866,31.8430667 140.077267,30.8694 140.077267,29.0607333 L140.077267,28.9814 C140.077267,26.9540667 138.4676,26.1987333 136.2216,26.1987333 L131.1936,26.1987333 L131.1936,31.8430667 L136.2216,31.8430667 Z M129.345267,24.7877333 L136.1026,24.7877333 C139.2626,24.7877333 141.9256,25.9604 141.9256,28.9614 L141.9256,29.0407333 C141.9256,31.5054 139.838933,32.7374 137.851267,33.0950667 L143.058267,38.9977333 L140.8326,38.9977333 L135.7446,33.2344 L131.1936,33.2344 L131.1936,38.9977333 L129.345267,38.9977333 L129.345267,24.7877333 Z"
                  id="Fill-28"
                  fill="#000000"
                ></path>
                <polygon
                  id="Fill-30"
                  fill="#000000"
                  points="151.166433 26.2187333 145.323433 26.2187333 145.323433 24.7877333 158.857767 24.7877333 158.857767 26.2187333 153.014433 26.2187333 153.014433 38.9977333 151.166433 38.9977333"
                ></polygon>
                <path
                  d="M161.440833,34.6653333 L163.249167,34.6653333 C163.5475,36.4143333 164.878833,37.7656667 168.675167,37.7656667 C171.715833,37.7656667 173.424833,36.7323333 173.424833,35.003 C173.424833,33.3336667 172.172833,32.797 168.2775,32.34 C164.362167,31.883 162.176167,30.949 162.176167,28.405 C162.176167,26.2186667 164.461833,24.609 168.198167,24.609 C171.8945,24.609 174.259833,25.9206667 174.637167,28.524 L172.947833,28.524 C172.510833,26.676 170.9605,26.02 168.198167,26.02 C165.197167,26.02 163.905167,26.9343333 163.905167,28.3653333 C163.905167,29.637 164.839167,30.3723333 168.814167,30.8296667 C172.987833,31.3063333 175.2135,32.1413333 175.2135,34.9433333 C175.2135,37.408 172.788833,39.1766667 168.675167,39.1766667 C163.905167,39.1766667 161.7985,37.1693333 161.440833,34.6653333"
                  id="Fill-32"
                  fill="#000000"
                ></path>
                <g id="Group-36" transform="translate(162.333333, 0.666667)">
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3"></use>
                  </mask>
                  <g id="Clip-35"></g>
                  <path
                    d="M5.31973333,6.20116667 C7.31606667,5.34583333 8.06273333,4.4705 8.06273333,3.3365 C8.06273333,2.24283333 7.28173333,1.38716667 5.91006667,1.38716667 C4.64273333,1.38716667 3.6354,2.1035 3.6354,3.51583333 C3.6354,4.58983333 4.36473333,5.4255 5.31973333,6.20116667 L5.31973333,6.20116667 Z M5.0244,13.2631667 C6.69106667,13.2631667 7.9414,12.6065 8.79206667,11.6915 L4.69473333,7.49416667 C3.0104,8.11083333 1.77773333,9.04583333 1.77773333,10.7365 C1.77773333,12.2885 2.92373333,13.2631667 5.0244,13.2631667 L5.0244,13.2631667 Z M9.6254,12.5471667 C8.58373333,13.7208333 7.05573333,14.6358333 4.98973333,14.6358333 C2.07306667,14.6358333 0.145733333,13.2231667 0.145733333,10.8361667 C0.145733333,8.5685 1.8994,7.33516667 3.70506667,6.65883333 C2.7674,5.82316667 2.1424,4.8285 2.1424,3.49583333 C2.1424,1.3075 3.93073333,0.0745 5.94473333,0.0745 C8.0974,0.0745 9.53873333,1.46683333 9.53873333,3.3765 C9.53873333,4.78883333 8.4274,6.1615 6.25706667,7.01683333 L9.71206667,10.5178333 L11.9517333,7.15616667 L13.4797333,7.15616667 L10.6324,11.4528333 L13.6187333,14.4965 L11.5177333,14.4965 L9.6254,12.5471667 Z"
                    id="Fill-34"
                    fill="#000000"
                    mask="url(#mask-4)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
