import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import React from 'react';

import {
  StyledAhipLogo,
  StyledClearEstateLogo,
  StyledGoodbillLogo,
  StyledMarbleLogo,
} from './shared';

export const heading = 'Experts by your side throughout retirement';
export const body =
  "There's more to retirement than just your financial plan. Our expert partners help you navigate the rest.";

export const partners = [
  {
    heading: 'Lower your hospital bill',
    body:
      'Let medical billing experts review your bill for inflated charges and negotiate a discount for you.',
    features: [
      'Save 20-60% on your hospital bill',
      '100% risk-free with no upfront payment',
      'Retirable members get special benefits',
    ],
    additionalText: 'Powered by:',
    image: <StyledGoodbillLogo />,
    button: (
      <GetStartedButton
        marginTop={32}
        to="https://app.goodbill.com/partners/welcome?partner=retirable"
      />
    ),
  },
  {
    heading: 'Settle your estate',
    body: "Everything you need to settle your loved one's estate.",
    features: [
      'Probate, tax filings and government agency relations',
      'Expert care to remove the bureaucratic burden of Estate Settlement',
      'One-on-one expert support to set your affairs in order',
    ],
    additionalText: 'Powered by:',
    image: <StyledClearEstateLogo />,
  },
  {
    heading: 'Save on insurance',
    body: 'Save money and stay organized while protecting what you love.',
    features: [
      'Access to all of your P&C insurance policies in one secure digital account',
      'Personalized alerts if you could save on your P&C insurance',
      'Notifications when your rates might be going up, when your policies are expiring, and more',
    ],
    additionalText: 'Powered by:',
    image: <StyledMarbleLogo />,
  },
  {
    heading: 'Assistance with Medicare',
    body:
      "We'll work with your insurance agent to make sure your health plans are built into your financial plan.",
    features: [
      'Plan for current and future costs',
      'Reminders to contact your agent before open enrollment',
      'Quickly revisit your planning if your health changes',
    ],
    image: <StyledAhipLogo />,
  },
];
