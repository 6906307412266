import React, { FC, SVGProps } from 'react';

export const Kroger: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 611.7 227.17"
    {...props}
  >
    <path
      className="cls-1"
      fill="#21409a"
      d="M529.46,274V263.34H510.05v65.55h19.87V292.57q5.12-11.38,17.26-11.39a33.59,33.59,0,0,1,7,.7V263.05a18.24,18.24,0,0,0-6.22-.93q-10.11,0-18.53,11.91"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M583.46,307.37a17.53,17.53,0,0,1,0-22.54,14.26,14.26,0,0,1,20.16-.9q.46.44.9.9a17.44,17.44,0,0,1,0,22.51,14.3,14.3,0,0,1-20.21.85c-.29-.27-.58-.55-.85-.85m34.39,12.89q9.63-9.8,9.62-24.2a33.12,33.12,0,0,0-9.62-24.12,33.85,33.85,0,0,0-47.73,0,35,35,0,0,0,0,48.16,33.84,33.84,0,0,0,47.73.11"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M730,289.84a16.06,16.06,0,0,1,4.94-8.42,12.14,12.14,0,0,1,8.13-3,11.29,11.29,0,0,1,8,3.44,13.71,13.71,0,0,1,4.27,7.95Zm13.48-27.72A31.76,31.76,0,0,0,720,271.77q-9.59,9.64-9.58,24.29,0,15,9.18,24.49t24,9.5q10,0,17.09-3.73a47.11,47.11,0,0,0,13-10.32l-14.41-8.06q-5.5,5.06-14.44,5.06-12.86,0-15.11-11.39h45.09a14.18,14.18,0,0,1,.12-1.59c.07-.68.13-1.3.17-1.86s.06-1,.06-1.43q0-14.4-8.89-24.52T743.44,262.1"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M821.49,262.12q-10.11,0-18.54,11.91V263.34H783.54v65.55h19.88V292.57q5.1-11.38,17.25-11.39a33.59,33.59,0,0,1,7,.7V263.05a18.18,18.18,0,0,0-6.21-.93"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M490.89,251a61.88,61.88,0,0,0,.53-6.8h0a46.51,46.51,0,0,1,93-1.93c0,.65,0,1.29,0,1.93H603.2a65.24,65.24,0,0,0-130.47-1.12h0a42.43,42.43,0,0,1-.39,5.52c-.86,6.66-3.35,16.26-10.31,24.18-5.45,6.2-12.91,10.39-22.25,12.57V241.78h-21v87.11h21V304.48a69.92,69.92,0,0,0,17.87-5.54l19,29.95h25.16L474,287.35c.7-.72,1.4-1.45,2.07-2.21,10.09-11.48,13.63-24.91,14.82-34.16"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M679.18,306.24a14.78,14.78,0,0,1-20.9.26l-.26-.26a17.07,17.07,0,0,1,0-22.17,14.75,14.75,0,0,1,20.84-.29l.29.29a17.22,17.22,0,0,1,0,22.17M702,348.05V263.34H683.1v5.58a22.72,22.72,0,0,0-16.91-6.8q-12.62,0-21.85,9.49t-9.24,23.52q0,14,9.47,23.28t21.62,9.31a28.29,28.29,0,0,0,17.14-5.44v25.77h0a40,40,0,1,1-80,0h-18.7A58.71,58.71,0,0,0,702,349.79h0v-1.74Z"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M708.2,378.25h.8c.87,0,1.57-.29,1.57-1s-.45-1-1.45-1a3.66,3.66,0,0,0-.87.08Zm0,3.85h-1.57v-6.79a12.71,12.71,0,0,1,2.61-.21,4.21,4.21,0,0,1,2.36.5,1.89,1.89,0,0,1,.66,1.49,1.74,1.74,0,0,1-1.41,1.57v.09a2,2,0,0,1,1.24,1.65,5.37,5.37,0,0,0,.5,1.7h-1.7a6,6,0,0,1-.54-1.66c-.12-.74-.54-1.07-1.41-1.07h-.74Zm-4.2-3.56a5.26,5.26,0,0,0,5.1,5.42h.2a5.2,5.2,0,0,0,5.18-5.22v-.16a5.24,5.24,0,1,0-10.48,0v0m12.22,0a7,7,0,1,1-7.2-6.8h.2a6.84,6.84,0,0,1,7,6.66v.14"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M281.11,319.05A11.12,11.12,0,1,1,270,307.78a11.12,11.12,0,0,1,11.11,11.27Z"
      transform="translate(-215.97 -178.98)"
    />
    <ellipse className="cls-1" cx="146.01" cy="140.07" rx="11.12" ry="11.26" />
    <path
      className="cls-1"
      fill="#21409a"
      d="M269.33,245.12a2.57,2.57,0,0,1,.62-2,2.52,2.52,0,0,1,1.9-.87H302a2.52,2.52,0,0,1,2.2,1.29,2.62,2.62,0,0,1,0,2.57l-15.53,27.26a2.53,2.53,0,0,1-3.45,1,2.63,2.63,0,0,1-.4-.28,45.65,45.65,0,0,1-15.34-27.68C269.44,246.07,269.39,245.62,269.33,245.12Zm48.78,39.61a44.08,44.08,0,0,1-21.69-3.44,2.56,2.56,0,0,1-1.42-1.55,2.61,2.61,0,0,1,.21-2.1l18-31.53a2.52,2.52,0,0,1,4.4,0L335,276.63a2.55,2.55,0,0,1-1.07,3.56,44.62,44.62,0,0,1-15.82,4.54Zm30.51-16.28a45.62,45.62,0,0,1-3.53,3.83A2.5,2.5,0,0,1,343,273a2.53,2.53,0,0,1-1.84-1.26L326.55,246.1a2.57,2.57,0,0,1,0-2.57,2.52,2.52,0,0,1,2.2-1.29h27.39a2.54,2.54,0,0,1,1.91.87,2.65,2.65,0,0,1,.62,2,45.73,45.73,0,0,1-10.05,23.34Z"
      transform="translate(-215.97 -178.98)"
    />
    <path
      className="cls-1"
      fill="#21409a"
      d="M367.64,242.24c-1.78,26-22,47.68-48.41,50.15-29.33,2.74-55.43-19.23-58.13-49C258.65,216.51,241.36,199,216,197.78v18.73c15.69,1.15,25.19,11.25,26.77,28.6,3.43,37.73,34.84,66.16,71.54,66.16q3.29,0,6.65-.31c36-3.36,63.38-33.2,65.18-68.75Z"
      transform="translate(-215.97 -178.98)"
    />
  </svg>
);
