import { TabbedCard } from 'component-library/components/cards/tabbed-card/TabbedCard';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { MORE_WAY_TO_SAVE_TABS } from 'components/pages/benefits/more-ways-to-save/moreWaysToSaveUtils';
import React from 'react';
import styled from 'styled-components';

const WaysToSaveWrapper = styled(ShowOn)`
  padding: 0 16px;
  margin-bottom: 40px;
`;

export const WaysToSaveSmall = () => (
  <WaysToSaveWrapper screens={[Screen.Sm, Screen.Md]}>
    <TabbedCard tabs={MORE_WAY_TO_SAVE_TABS} />
  </WaysToSaveWrapper>
);
