import React, { FC, SVGProps } from 'react';

export const Albertsons: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Layer_3"
    data-name="Layer 3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 264 40"
    {...props}
  >
    <path
      className="cls-1"
      fill="#009fe0"
      d="M67.27,30.79v0a6.78,6.78,0,0,0-.47-1.07,7.37,7.37,0,0,0-.64-1C58.27,19.43,50.19,9.57,42.4,0L34.88,9l-.29.36c5.8,6.84,11.52,13.62,17.13,20.53l.21.3.27.43a7.86,7.86,0,0,1-.39,8.12L51,39.91H65A8.7,8.7,0,0,0,67.27,30.79Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-2"
      fill="#00529f"
      d="M29.58,23.68c.74,2.78,3.13,5,4.29,7.45v0c1.16-2.48,3.55-4.67,4.29-7.45C39.62,19.39,36,16,33.89,12.62v0C31.75,16,28.14,19.39,29.58,23.68Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-2"
      fill="#00529f"
      d="M33.88,33c-.27-.63-.87-1.75-.87-1.75s-2.19-5.15-9-5c-2,.05-2.58.09-2.94.1s-.63,0-1,0l0,0a7,7,0,0,0-.18,1.33,7,7,0,0,0,2.38,5.9c1.83,1.86,3.7,2,6.5,2.14s3.46.88,4,1.5a7.74,7.74,0,0,1,1.06,2.19v0a7.74,7.74,0,0,1,1.06-2.19c.56-.63,1.22-1.34,4-1.5s4.68-.28,6.5-2.14a7,7,0,0,0,2.38-5.9,7,7,0,0,0-.18-1.33l0,0c-.34,0-.61,0-1,0s-.94,0-2.94-.1c-6.81-.18-9,5-9,5S34.15,32.36,33.88,33Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-2"
      fill="#00529f"
      d="M40.93,0H26a.13.13,0,0,0-.1,0C17.74,9.33,9.56,19.32,1.58,28.75a5.62,5.62,0,0,0-.64,1,6.45,6.45,0,0,0-.47,1.07v0A8.7,8.7,0,0,0,2.76,39.9h14l-.82-1.2a7.86,7.86,0,0,1-.39-8.12l.26-.42,0,0,.21-.29c8-9.89,16.3-19.54,24.7-29.44L41,0h-.11Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M258.21,18.47a2.9,2.9,0,1,1,2.92,2.88,2.9,2.9,0,0,1-2.92-2.88m5.44,0A2.54,2.54,0,1,0,261.13,21a2.54,2.54,0,0,0,2.52-2.57m-1.09,1.63h-.47l-.94-1.48h-.73v1.49H260l0-3.23h1.41a1.48,1.48,0,0,1,.79.15.8.8,0,0,1,.37.69c0,.64-.44.88-1,.9ZM261,18.27c.46,0,1.16.07,1.16-.59,0-.5-.45-.56-.85-.55h-.93v1.14Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M176.05,38c.07-.57-.47-1-.87-1.35a11.32,11.32,0,0,1-1.36-1.17,2.25,2.25,0,0,1-.48-1.49c0-.65-.06-1.3-.08-1.95q-.07-2.06,0-4.11c0-1.34.1-2.71.18-4.05s1.27-1.2,2.26-1.31a1.84,1.84,0,0,0,1.45-.67,3,3,0,0,0,.29-1.4,2.65,2.65,0,0,0-.22-1.64A1.49,1.49,0,0,0,176,18.4c-.89,0-2,.23-2.16-.95s.36-2.17.12-3.25c-.11-.49-.46-1-1-1a2.69,2.69,0,0,0-1.42.75c-1.05.82-2.05,1.69-3.07,2.55a7.15,7.15,0,0,1-3.24,1.89c-1.27.24-2.57.09-3.85.27a4.5,4.5,0,0,0-2.89,1.62c-.23.28-.63.88-1,.89s-.3-.39-.3-.61a2.76,2.76,0,0,0-.44-2.06,2.22,2.22,0,0,0-1.53-.43c-.64,0-1.29,0-1.92,0s-1.39.11-2.08.19a4.59,4.59,0,0,0-1.69.42,1.2,1.2,0,0,0-.74,1.26c.13.56.69.9,1.05,1.3a2.2,2.2,0,0,1,.51,1.51c0,.63,0,1.26.07,1.88a81,81,0,0,1-.06,8.43c0,.68-.1,1.36-.15,2a2.61,2.61,0,0,1-.66,1.6,2,2,0,0,0-.62,1.34,1.42,1.42,0,0,0,.8,1.18,3.86,3.86,0,0,0,1.65.32l1.82.06a30.78,30.78,0,0,0,3.56-.07c.77-.07,1.74-.24,2.07-1.06s-.29-1.55-.72-2.28a2.46,2.46,0,0,1-.32-1.36c0-.67,0-1.34,0-2V28.62c0-.7,0-1.39,0-2.09a3.25,3.25,0,0,1,.35-1.65c.94-1.65,3-1.85,4.72-1.82.42,0,.84,0,1.26.06a3.72,3.72,0,0,1,1.23.19,1.05,1.05,0,0,1,.62,1.11c0,.64,0,1.29,0,1.93q-.06,2.14-.11,4.28c0,1.34-.13,2.68-.12,4a6,6,0,0,0,.8,3.37s.92,1.64,4.2,1.66S175.87,39.52,176.05,38Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M74.29,39.95C70.87,39.85,71.62,37,71.62,37l2.25-5.82C75.59,27,77,22.68,78.67,18.42l1-2.7a5.29,5.29,0,0,0,.42-2s-.17-.94-.47-1.17-1-2.28,3.08-2.15a46.43,46.43,0,0,1,6.22.4S90.36,11,91,13.7l5.77,21.7s.4,1.49,1.46,1.47c1.45,0,1.38-2.76,1.38-2.76s.09-1.17.1-1.57c.13-4.22.25-9.12,0-13.54l-.11-1.72c-.11-1.88-3.33-3.26-.52-4.51a54.09,54.09,0,0,1,5.6-2.3s2.65-1,2.54,1.32c-.32,7-.57,14.29-.41,21,0,.27.07,2.39.07,2.39,0,1,1,1.63,1.41,2.42,0,0,.71,1.13-1.52,2-1.72.64-11.33.37-15.2.34-3.55,0-3.26-2-3.26-2,.19-1.08.31-.85.83-1.78a2.19,2.19,0,0,0,0-1.94h0c-.27-.71-.93-3.38-.93-3.38A1.16,1.16,0,0,0,87.06,30c-1.51-.1-3.19.17-4.53-.3a3.57,3.57,0,0,1-2-1.64s-.23-.34-.5-.32-.34.34-.34.34c-.83,2.41-1.5,4.68-2.23,7-.64,2.08,1.24,2.23.83,3.81C78.31,38.89,78.34,40.06,74.29,39.95Zm9.81-22.63c-.57,0-1,.41-1.29,1.07l-2.09,6.28s-.13.54.32.64a16.93,16.93,0,0,0,2.44.35,6.6,6.6,0,0,0,.89,0l.63,0a2.25,2.25,0,0,0,1.75-.58s.18-.18.06-.71c-.08-.37-.7-3.22-1.08-4.93C85.73,19.45,85.26,17.3,84.09,17.32Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M192.78,37.94a7,7,0,0,0,1.68-4.09,7.07,7.07,0,0,0-.75-4.58c-1.76-2.57-5.7-2.6-8.26-4.44A2.58,2.58,0,0,1,184.19,23a1.94,1.94,0,0,1,1.89-1.88,3.91,3.91,0,0,1,2.74.45,1.57,1.57,0,0,1,.34.32c.79,1-.38,1.9-.61,2.93-.08.37.09.52.09.52a1.79,1.79,0,0,0,1.21.6,2,2,0,0,0,1.68-.37,6,6,0,0,0,1.79-2.3,5.3,5.3,0,0,0,.63-3,2,2,0,0,0-1.06-1.34c-3.69-2-8.63-1.76-12.52,0a4.54,4.54,0,0,0-2.34,4A16.33,16.33,0,0,0,178,26c.36,2.62,2.61,4.08,4.83,4.86,0,0,5.21,1.56,5.48,3.19s-1.11,2.52-2.73,2.32a7,7,0,0,1-3.32-1A12,12,0,0,1,180,32.72a1.16,1.16,0,0,0-.51-.39,1.35,1.35,0,0,0-1.51.18.64.64,0,0,0-.18.34c-.16.92-.78,5.15,1.39,6.13C181.15,39.88,189.86,41,192.78,37.94Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M256.14,37.94a7,7,0,0,0,1.68-4.09,7.07,7.07,0,0,0-.75-4.58c-1.76-2.57-5.7-2.6-8.26-4.44A2.58,2.58,0,0,1,247.55,23a1.94,1.94,0,0,1,1.89-1.88,3.91,3.91,0,0,1,2.74.45,1.57,1.57,0,0,1,.34.32c.79,1-.38,1.9-.61,2.93-.08.37.09.52.09.52a1.79,1.79,0,0,0,1.21.6,2,2,0,0,0,1.68-.37,6,6,0,0,0,1.79-2.3,5.3,5.3,0,0,0,.63-3,2,2,0,0,0-1.06-1.34c-3.69-2-8.63-1.76-12.52,0a4.54,4.54,0,0,0-2.34,4,16.33,16.33,0,0,0,0,3.19c.36,2.62,2.61,4.08,4.83,4.86,0,0,5.21,1.56,5.48,3.19s-1.11,2.52-2.73,2.32a7,7,0,0,1-3.32-1,12,12,0,0,1-2.32-2.73,1.16,1.16,0,0,0-.51-.39,1.35,1.35,0,0,0-1.51.18.64.64,0,0,0-.18.34c-.16.92-.78,5.15,1.39,6.13C244.51,39.88,253.22,41,256.14,37.94Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M233.29,18.32c-1.13-1.21-2.79-.87-4.53-.32-1.58.5-2.47,1.38-3.78,1.89-.56.21-.66-.15-.66-.15-.13-.31,0-.73-.24-1.24-.42-.81-1.31-.83-2.12-.77s-4,.62-4,.62a3.58,3.58,0,0,0-1.79.93c-.81,1.33,1.08,2.13,1.14,3.4a80.81,80.81,0,0,1,.21,12.67c-.25,1-1.26,1.64-1.15,2.66,0,0,0,1.68,3.63,1.72s5.44-.18,5.79-1.44a2.64,2.64,0,0,0-.71-2c-1.1-1.24-.8-10.58-.8-10.58s-.33-2.1,1.35-2.29a2.11,2.11,0,0,1,2.19,1.47c1.31,4.16,2.15,8.69,3.31,13a3.07,3.07,0,0,0,3.09,1.89c2.51,0,4.81.08,5.62-.81a2,2,0,0,0-.7-2.84,3,3,0,0,1-.63-1c-1.51-4.28-2.33-8.87-3.69-13.26-.53-1.71-.65-2.4-1.56-3.45"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M209.46,39.72a24.58,24.58,0,0,1-9.38-.37c-2.58-.78-3.71-3.12-4.12-5.54a28.21,28.21,0,0,1,0-10.19c.39-2,1-4.18,3.07-5a17,17,0,0,1,10.35-.73,5.44,5.44,0,0,1,2,.89,7.94,7.94,0,0,1,3.06,4A27.14,27.14,0,0,1,215.36,33S214.63,38.93,209.46,39.72Zm-3.76-18.51a2.86,2.86,0,0,0-3.36,2.91,50.31,50.31,0,0,0,.33,8.1,5.13,5.13,0,0,0,1.68,3.39,3.2,3.2,0,0,0,2.12.47s1.64-.26,2.06-1.09c.62-1.23.66-2.25.62-4.23,0,0-.19-6.8-1.48-8.4A3.32,3.32,0,0,0,205.71,21.22Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M122.45,39.65c-3.57.66-9.88,0-9.88,0-1.26,0-2.56-.27-3-1s.5-2.23.5-2.23a1.28,1.28,0,0,0,.25-.64,126.33,126.33,0,0,0,.24-16l-.08-2.11c-.07-1.32-2.52-1.88-.91-3.28a34.18,34.18,0,0,1,6.28-3.35c.86-.36,1.15,0,1.15,0,.32.24.31.9.31.9v7.41s-.08.75.4.54c1.11-.49,2.35-1.12,3.53-1.6a3.57,3.57,0,0,1,4.14,1.11c1.84,2,3,7.35,3,7.35.8,3.76,1.08,8.48-1.87,11.45C125.52,39.24,122.45,39.65,122.45,39.65Zm-1-4a2.82,2.82,0,0,0,.69-1.37c.49-1.93-.14-4-.52-6L121.38,27c-.38-1.38-.67-2.94-2-3.57a1.17,1.17,0,0,0-.6-.09,1.62,1.62,0,0,0-1.26.61s-.32.26-.4,1.23c0,0-.15,7-.07,8.35a5.48,5.48,0,0,0,.35,1.85,1.77,1.77,0,0,0,.87.91,2.52,2.52,0,0,0,1.23.23A3,3,0,0,0,121.5,35.63Z"
      transform="translate(0 0)"
    />
    <path
      className="cls-1"
      fill="#009fe0"
      d="M145.16,30.51a1.49,1.49,0,0,1,.84.18c1,.49,2,1.07,2,2.26a6.6,6.6,0,0,1-3.42,5.88,14.6,14.6,0,0,1-9,.6A6.26,6.26,0,0,1,131.24,36a25.94,25.94,0,0,1,.15-13.78,6.3,6.3,0,0,1,.88-2,7.09,7.09,0,0,1,4.88-2.4,15.22,15.22,0,0,1,6,.24,5.64,5.64,0,0,1,1.8.75,6.23,6.23,0,0,1,1.43,1.4,5.67,5.67,0,0,1,.42.65,10.66,10.66,0,0,1,1.31,6.93,2.67,2.67,0,0,1-.18.57,1.63,1.63,0,0,1-.65.73,13.18,13.18,0,0,1-4,1.44s-3.33.72-4.82,1c0,0-1.4.18-1.31,1,.27,2.51,1.09,3.18,1.09,3.18a2.6,2.6,0,0,0,1.27.86,3.78,3.78,0,0,0,3.25-.71s.88-.47,1.31-3c0,0,.39-1.32.7-2.1A.46.46,0,0,1,145.16,30.51Zm-7.09-1.93c1.38.1,3.22-.11,3.76-1.39a6.41,6.41,0,0,0-.47-5.28,1.82,1.82,0,0,0-.7-.69,2.24,2.24,0,0,0-1.76-.11,1.63,1.63,0,0,0-.38.15,2.85,2.85,0,0,0-1.2,2.06,21.73,21.73,0,0,0-.2,4.22A1,1,0,0,0,138.08,28.58Z"
      transform="translate(0 0)"
    />
  </svg>
);
