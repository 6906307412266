import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { body, buttonCta, heading, linkTo } from './content';

const SpendingCardWrapper = styled(ShowOn)`
  background: ${colors.navyBlue};
  border-radius: 32px;
  margin: 0 40px 80px;
`;

const InnerWrapper = styled(Flex)`
  max-width: 1480px;
  padding: 80px 40px;
  margin: 0 auto;

  gap: 80px;

  > div {
    flex-basis: 50%;
  }
`;

export const SpendingCardLarge = () => (
  <SpendingCardWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <InnerWrapper>
      <Flex column justifyContent="center">
        <ResponsiveHeading marginBottom={16} variant={2} color={colors.white}>
          {heading}
        </ResponsiveHeading>
        <Body marginBottom={48} variant={1} color={colors.white}>
          {body}
        </Body>
        <LinkWrapper to={linkTo}>
          <PrimaryButton withCaret buttonColor={ButtonColor.Peach}>
            {buttonCta}
          </PrimaryButton>
        </LinkWrapper>
      </Flex>
      <Flex alignItems="center">
        <StaticImage
          src="../images/spending-card-large.webp"
          alt="spending-card"
        />
      </Flex>
    </InnerWrapper>
  </SpendingCardWrapper>
);
