import { ButtonColor } from 'component-library/components/buttons/PrimaryButton';
import { FlexProps } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';

import { spendConfidently } from './content';
import { HighlightButton, HighlightCard } from './shared';

export const SpendConfidently: FC<FlexProps> = (props) => (
  <HighlightCard backgroundColor={colors.navyBlue} {...props}>
    <Body variant={3} marginBottom={12} color={colors.white}>
      {spendConfidently.heading}
    </Body>
    <Body variant={1} marginBottom={32} color={colors.white}>
      {spendConfidently.body}
    </Body>
    <LinkWrapper to="/spending">
      <HighlightButton withCaret buttonColor={ButtonColor.Peach}>
        {spendConfidently.buttonCta}
      </HighlightButton>
    </LinkWrapper>
  </HighlightCard>
);
