import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { CardItem } from './moreWaysToSaveUtils';

const BenefitCard = styled(Flex)`
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  padding: 32px 24px;
  margin-bottom: 24px;
`;

const TextSection = styled(Flex)`
  border-bottom: 1px solid #dadae2;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const IconWrapper = styled(Flex)`
  width: 200px;
  height: 56px;
`;

interface BenefitCardProps extends CardItem {}

export const BenefitCardSmall: FC<BenefitCardProps> = ({
  name,
  benefit,
  eligibility,
  icon,
}) => (
  <BenefitCard column>
    <TextSection column alignItems="center">
      <Body variant={1} marginBottom={12}>
        {name}
      </Body>
      <IconWrapper justifyContent="center">{icon}</IconWrapper>
    </TextSection>
    <TextSection column alignItems="center">
      <Body medium variant={2} marginBottom={12}>
        Benefit
      </Body>
      <Body variant={2}>{benefit}</Body>
    </TextSection>
    <Flex column alignItems="center">
      <Body medium variant={2} marginBottom={12}>
        Eligibility
      </Body>
      <Body alignment="center" variant={2}>
        {eligibility}
      </Body>
    </Flex>
  </BenefitCard>
);
