import React from 'react';

import { SpendingCardLarge } from './SpendingCardLarge';
import { SpendingCardSmall } from './SpendingCardSmall';

export const SpendingCard = () => (
  <div id="spending-card-benefit">
    <SpendingCardSmall />
    <SpendingCardLarge />
  </div>
);
