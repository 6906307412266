import React, { FC, SVGProps } from 'react';

export const BestWestern: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    version="1.1"
    id="BW_Master_Brand_B_x26_W"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 792 199.5"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              fill="#FFFFFF"
              d="M665.33,99.28c0.18-0.14,0.45-0.16,0.64-0.02c0.11,0.08,0.18,0.21,0.2,0.41c0.06,0.46,0.06,0.91,0.06,1.37
					c0,7.84-7.56,9.44-13.74,9.44c-14.58,0-25.55-9.61-25.55-24.62c0-13.74,8.6-23.35,22.43-23.35c10.96,0,18.55,6.83,18.55,17.79
					c0,1.18-0.08,2.28-0.25,3.46l-28.66,3.96c1.01,8.43,6.71,14.16,16.26,14.16C658.58,101.88,662.56,101.32,665.33,99.28
					 M648.28,69.68c-6.74,0-8.94,4.89-9.44,11.04l17.11-2.62C655.95,73.56,653.51,69.68,648.28,69.68z"
            />
            <path
              className="st0"
              d="M356.58,99.27c0.2-0.13,0.51-0.16,0.73-0.02c0.11,0.08,0.18,0.21,0.2,0.41c0.07,0.46,0.07,0.92,0.07,1.37
					c0,7.84-7.59,9.45-13.74,9.45c-14.67,0-25.54-9.61-25.54-24.62c0-13.74,8.51-23.35,22.34-23.35c10.96,0,18.63,6.83,18.63,17.79
					c0,1.18-0.08,2.28-0.25,3.45l-28.66,3.96c1.01,8.43,6.65,14.16,16.26,14.16C349.88,101.88,353.8,101.32,356.58,99.27
					 M339.53,69.68c-6.67,0-8.86,4.89-9.36,11.04l17.11-2.62C347.28,73.55,344.84,69.68,339.53,69.68z"
            />
            <path
              className="st0"
              d="M627.72,100.78c0.09,0.39,0.14,1.16,0.14,1.68c0,5.99-6.41,8.01-11.29,8.01c-9.53,0-15.51-5.65-15.51-15.43
					V72.2h-5.21v-8.35h5.21v-8.93h12.56v8.93h12.38v8.35h-12.3v22.42c0,4.72,2.78,7.5,7.67,7.5c1.99,0,4.13-0.53,5.57-1.65
					c0.18-0.14,0.38-0.16,0.55-0.07C627.63,100.48,627.7,100.63,627.72,100.78z"
            />
            <path
              className="st0"
              d="M428.56,100.79c0.09,0.39,0.14,1.16,0.14,1.68c0,5.98-6.49,8.01-11.3,8.01c-9.61,0-15.51-5.65-15.51-15.43
					V72.2h-5.29v-8.35h5.29v-8.94h12.57v8.94h12.38v8.35h-12.31v22.42c0,4.72,2.79,7.5,7.6,7.5c1.99,0,4.2-0.53,5.65-1.65
					c0.17-0.14,0.38-0.16,0.55-0.07C428.47,100.49,428.53,100.64,428.56,100.79z"
            />
            <path
              className="st0"
              d="M669.08,69.89c0.59-4.42,4.41-6.71,8.57-6.71c4.97,0,8.46,3.04,8.46,8.26v1.69h0.26
					c3.76-5.4,7.39-10.62,14.18-10.62c0.69,0,1.46,0.08,2.22,0.25l-0.67,12.14c-1.29-0.43-2.59-0.59-3.96-0.59
					c-4.47,0-8.01,3.71-11.33,7v27.82h-12.53V73.89c0-1.85-0.61-3.96-2.87-3.96c-0.6,0-1.19,0.13-1.75,0.4
					c-0.14,0.08-0.31,0.06-0.44-0.05C669.1,70.18,669.06,70.04,669.08,69.89z"
            />
            <path
              className="st0"
              d="M751.07,102.56c0.13-0.08,0.31-0.09,0.44,0.11c0.08,0.07,0.13,0.16,0.11,0.37c-0.4,3.72-3.43,6.76-8.5,6.76
					c-5.9,0-9.1-3.79-9.1-10.12V78.61c0-3.29-2.03-4.89-4.47-4.89c-4.56,0-8.51,4.47-11.13,7.16v28.25h-12.64V63.86h12.39v8.6h0.25
					c5.05-5.48,10.53-9.95,17.79-9.95c2.44,0,5.09,0.84,6.99,2.45c3.12,2.6,3.46,6.57,3.46,10.44v23.61c0,2.19,0.67,4.05,2.7,4.05
					C750.04,103.06,750.6,102.92,751.07,102.56z"
            />
            <path
              className="st0"
              d="M561.99,99.69c3.01,1.79,6.74,2.69,10.56,2.69c4.17,0,7.5-1.43,7.5-4.98c0-3.03-2.98-4.47-7.34-6.57
					c-5.9-3.04-10.88-6.16-10.88-13.83c0-6.91,5.82-14.33,19.14-14.33c3.63,0,6.91,0.34,9.93,0.84l-1.49,8.6
					c-2.36-0.76-5.49-1.26-8.35-1.26c-4.6,0-7.08,1.86-7.08,4.47c0,2.78,3.36,4.47,7.59,6.58c5.65,2.78,11.13,5.98,11.13,13.74
					c0,6.15-4.72,14.84-18.8,14.84c-8.09,0-12.81-3.04-12.81-9.02c0-0.46,0-0.92,0.06-1.38c0.02-0.17,0.12-0.35,0.3-0.43
					C561.62,99.56,561.83,99.6,561.99,99.69z"
            />
            <path
              className="st0"
              d="M362.82,99.69c2.93,1.79,6.66,2.69,10.56,2.69c4.13,0,7.41-1.43,7.41-4.97c0-3.04-2.95-4.47-7.25-6.58
					c-5.98-3.04-10.88-6.15-10.88-13.82c0-6.91,5.74-14.33,19.06-14.33c3.7,0,7,0.33,9.94,0.84l-1.43,8.6
					c-2.36-0.76-5.49-1.26-8.43-1.26c-4.55,0-7,1.86-7,4.47c0,2.78,3.29,4.47,7.5,6.58c5.74,2.78,11.13,5.98,11.13,13.74
					c0,6.15-4.63,14.84-18.71,14.84c-8.18,0-12.81-3.04-12.81-9.02c0-0.46,0-0.92,0.06-1.38c0.02-0.17,0.12-0.35,0.29-0.43
					C362.45,99.56,362.67,99.6,362.82,99.69z"
            />
            <path
              className="st0"
              d="M555.82,99.28c0.18-0.14,0.45-0.16,0.65-0.02c0.1,0.08,0.17,0.21,0.2,0.41c0.06,0.46,0.06,0.91,0.06,1.37
					c0,7.84-7.55,9.44-13.74,9.44c-14.58,0-25.54-9.61-25.54-24.62c0-13.74,8.6-23.35,22.42-23.35c10.96,0,18.55,6.83,18.55,17.79
					c0,1.18-0.09,2.28-0.25,3.46l-28.66,3.96c1.01,8.43,6.74,14.16,16.27,14.16C549.08,101.88,553.05,101.32,555.82,99.28
					 M538.77,69.68c-6.75,0-8.93,4.89-9.44,11.04l17.11-2.62C546.44,73.56,544,69.68,538.77,69.68z"
            />
            <path
              className="st0"
              d="M284.2,109.97c-5.66,0-11.7-0.55-17.3-1.57l-0.34-0.06V50.52l0.35-0.05c7.88-1.28,14.22-1.91,19.46-1.91
					h0.04c7.21,0,11.67,0.8,16.07,2.81c2.53,1.18,4.46,2.83,5.76,4.89c1.08,1.73,1.67,3.71,1.7,5.82c0.02,1.25-0.22,3.75-2.03,6.49
					c-1.55,2.37-3.86,4.32-6.97,5.81c2.07,0.5,4.06,1.34,5.68,2.23c2.99,1.65,5.17,3.82,6.47,6.47c1.23,2.51,1.68,5.45,1.32,8.81
					c-0.66,6.15-4.76,11.68-10.97,14.66c-3.81,1.77-8.1,2.82-13.24,3.19C288.29,109.9,286.24,109.97,284.2,109.97z M280.32,101.11
					c1.37,0.27,3.37,0.45,5.1,0.45c4.79,0,7.14-0.98,8.99-2.05c1.78-1.02,3.22-2.6,4.18-4.55c0.95-1.95,1.33-4.16,1.12-6.46
					c-0.39-4.11-3.08-7.08-7.57-8.36c-1.45-0.41-3.18-0.63-5.1-0.63c-2.16,0-4.48,0.31-6.71,0.88V101.11z M280.32,56.79v18.12
					c6.47-0.54,9.66-1.81,12.31-3.55c2.73-1.79,4.32-4.31,4.47-7.09c0.07-1.58-0.41-3.13-1.4-4.3c-1.17-1.41-3.03-2.42-5.51-2.98
					c-1.43-0.33-3.28-0.5-5.19-0.5C283.48,56.48,281.88,56.59,280.32,56.79z"
            />
            <path
              className="st0"
              d="M496.97,57.66c0.98-0.46,1.77-0.41,2.33-0.33c3.07,0.45,4.68,5.94,4.35,15.07
					c-0.16,4.41-1.07,11.08-3.16,16.93c-1.02,2.82-2.18,5.04-3.47,6.6c-1.39,1.71-2.96,2.58-4.57,2.6c-0.07,0-0.15,0-0.22,0
					c-0.37,0-0.81-0.01-1.18-0.1l-7.18-48.77h-14.18l5.34,32.3c-0.34,2.24-1.16,6.47-2.88,10.2c-1.69,3.58-3.76,5.6-6.24,6.01
					c-0.31,0.05-0.96,0.11-1.56,0.11c-0.26,0-0.5-0.01-0.69-0.03l-7.14-48.6h-14.19l9.82,59.54l0.26,0.07
					c1.85,0.45,3.77,0.68,5.66,0.68c1.57,0,3.09-0.16,4.5-0.47c3.54-0.78,6.94-2.87,9.68-6.04c2.15-2.57,3.85-5.64,4.87-8.88
					l2.42,14.66l0.26,0.06c2.62,0.57,4.36,0.69,6.34,0.69c0.55,0,1.13-0.01,1.66-0.02c3.59-0.06,7.04-1.05,10.08-2.93
					c2.84-1.77,5.36-4.28,7.46-7.55c3.01-4.48,5.31-10.04,6.86-16.63c1.37-5.7,2.08-11.93,2.08-17.87c0-4.98-0.77-8.88-2.29-11.68
					c-1.72-3.16-4.4-4.77-7.84-4.77c-1.85,0-3.73,0.78-5.17,2.14c-1.09,1.04-2.61,3.03-2.73,6.52c-0.01,0.16,0.05,0.34,0.19,0.44
					C496.59,57.72,496.81,57.74,496.97,57.66z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M350.9,166.38v-14.99h-14.21v14.99h-7.45v-32.67h7.45v12.59h14.21v-12.59h7.5v32.67H350.9z"
            />
            <path
              className="st0"
              d="M376.04,167.25c-7.96,0-12.03-6.01-12.03-13.51c0-7.87,5-12.87,12.82-12.87c7.5,0,11.85,4.63,11.85,12.17
					C388.67,160.87,384.18,167.25,376.04,167.25z M376.27,145.37c-4.21,0-5.09,4.4-5.09,7.68c0,3.33,0.69,9.72,5.32,9.72
					c4.12,0,5.05-5.41,5.05-8.89C381.54,150.5,380.71,145.37,376.27,145.37z"
            />
            <path
              className="st0"
              d="M613.33,167.25c-7.96,0-12.03-6.01-12.03-13.51c0-7.87,5-12.87,12.82-12.87c7.5,0,11.85,4.63,11.85,12.17
					C625.96,160.87,621.47,167.25,613.33,167.25z M613.56,145.37c-4.21,0-5.09,4.4-5.09,7.68c0,3.33,0.69,9.72,5.33,9.72
					c4.12,0,5.04-5.41,5.04-8.89C618.84,150.5,618,145.37,613.56,145.37z"
            />
            <path
              className="st0"
              d="M431.31,161.01c0.1-0.07,0.25-0.09,0.35-0.01c0.06,0.04,0.1,0.11,0.11,0.22c0.03,0.26,0.03,0.5,0.03,0.76
					c0,4.25-4.12,5.13-7.5,5.13c-7.95,0-13.93-5.23-13.93-13.42c0-7.5,4.68-12.77,12.22-12.77c5.97,0,10.14,3.75,10.14,9.71
					c0,0.65-0.05,1.25-0.14,1.9l-15.64,2.18c0.56,4.58,3.66,7.73,8.89,7.73C427.63,162.44,429.79,162.14,431.31,161.01 M422,144.86
					c-3.66,0-4.86,2.68-5.13,6.01l9.35-1.43C426.21,146.99,424.87,144.86,422,144.86z"
            />
            <path
              className="st0"
              d="M576.36,161.01c0.1-0.07,0.25-0.09,0.35-0.01c0.06,0.05,0.1,0.12,0.11,0.22c0.03,0.25,0.03,0.5,0.03,0.76
					c0,4.26-4.12,5.14-7.5,5.14c-7.96,0-13.93-5.23-13.93-13.42c0-7.5,4.67-12.77,12.22-12.77c5.96,0,10.13,3.75,10.13,9.71
					c0,0.65-0.04,1.25-0.14,1.9L562,154.72c0.55,4.58,3.65,7.73,8.88,7.73C572.68,162.44,574.83,162.14,576.36,161.01
					 M567.04,144.86c-3.66,0-4.86,2.68-5.14,6.01l9.35-1.43C571.25,146.99,569.91,144.86,567.04,144.86z"
            />
            <path
              className="st0"
              d="M409.1,161.85c0.05,0.22,0.08,0.64,0.08,0.92c0,3.24-3.52,4.35-6.16,4.35c-5.22,0-8.46-3.1-8.46-8.42
					v-12.45h-2.86v-4.59h2.86v-4.86h6.85v4.86h6.75v4.59h-6.71v12.22c0,2.59,1.53,4.12,4.16,4.12c1.09,0,2.27-0.29,3.05-0.91
					c0.1-0.07,0.21-0.09,0.3-0.04C409.05,161.68,409.08,161.76,409.1,161.85z"
            />
            <path
              className="st0"
              d="M667.31,161.85c0.05,0.22,0.08,0.64,0.08,0.92c0,3.24-3.52,4.35-6.16,4.35c-5.22,0-8.46-3.1-8.46-8.42
					v-12.45h-2.86v-4.59h2.86v-4.86h6.85v4.86h6.75v4.59h-6.71v12.22c0,2.59,1.53,4.12,4.17,4.12c1.09,0,2.26-0.29,3.06-0.91
					c0.09-0.07,0.21-0.09,0.3-0.04C667.26,161.68,667.3,161.76,667.31,161.85z"
            />
            <path
              className="st0"
              d="M629.02,144.98c0.33-2.44,2.39-3.68,4.66-3.68c2.74,0,4.63,1.67,4.63,4.53v0.92h0.14
					c2.04-2.96,4.03-5.83,7.74-5.83c0.37,0,0.78,0.05,1.19,0.14l-0.37,6.66c-0.69-0.23-1.38-0.32-2.12-0.32
					c-2.46,0-4.4,1.99-6.2,3.8v15.18h-6.85v-19.21c0-1.01-0.32-2.17-1.53-2.17c-0.33,0-0.66,0.08-0.96,0.22
					c-0.08,0.04-0.18,0.03-0.25-0.03C629.04,145.14,629.01,145.06,629.02,144.98z"
            />
            <path
              className="st0"
              d="M449.58,161.24c1.61,0.99,3.65,1.48,5.75,1.48c2.26,0,4.07-0.79,4.07-2.73c0-1.66-1.62-2.45-3.97-3.6
					c-3.24-1.62-5.92-3.33-5.92-7.54c0-3.75,3.14-7.82,10.41-7.82c1.98,0,3.79,0.18,5.41,0.46l-0.78,4.72
					c-1.3-0.41-3.01-0.69-4.58-0.69c-2.5,0-3.85,1.01-3.85,2.4c0,1.53,1.81,2.46,4.12,3.61c3.1,1.52,6.06,3.28,6.06,7.49
					c0,3.38-2.54,8.1-10.23,8.1c-4.44,0-6.99-1.67-6.99-4.9c0-0.25,0-0.51,0.03-0.76c0.01-0.1,0.07-0.2,0.16-0.24
					C449.38,161.17,449.5,161.19,449.58,161.24z"
            />
            <path
              className="st0"
              d="M581.15,161.24c1.61,0.99,3.65,1.48,5.75,1.48c2.26,0,4.07-0.79,4.07-2.73c0-1.66-1.62-2.45-3.98-3.6
					c-3.24-1.62-5.93-3.33-5.93-7.54c0-3.75,3.15-7.82,10.41-7.82c1.98,0,3.79,0.18,5.41,0.46l-0.78,4.72
					c-1.3-0.41-3.01-0.69-4.58-0.69c-2.5,0-3.85,1.01-3.85,2.4c0,1.53,1.81,2.46,4.13,3.61c3.1,1.52,6.06,3.28,6.06,7.49
					c0,3.38-2.54,8.1-10.23,8.1c-4.44,0-6.98-1.67-6.98-4.9c0-0.25,0-0.51,0.03-0.76c0.01-0.1,0.06-0.2,0.16-0.24
					C580.95,161.17,581.07,161.19,581.15,161.24z"
            />
            <path
              className="st0"
              d="M669.38,161.24c1.61,0.99,3.65,1.48,5.75,1.48c2.26,0,4.07-0.79,4.07-2.73c0-1.66-1.62-2.45-3.98-3.6
					c-3.24-1.62-5.92-3.33-5.92-7.54c0-3.75,3.15-7.82,10.41-7.82c1.99,0,3.79,0.18,5.41,0.46l-0.78,4.72
					c-1.3-0.41-3.01-0.69-4.58-0.69c-2.5,0-3.85,1.01-3.85,2.4c0,1.53,1.81,2.46,4.13,3.61c3.1,1.52,6.06,3.28,6.06,7.49
					c0,3.38-2.54,8.1-10.23,8.1c-4.44,0-6.98-1.67-6.98-4.9c0-0.25,0-0.51,0.03-0.76c0.01-0.1,0.07-0.2,0.16-0.24
					C669.17,161.17,669.29,161.19,669.38,161.24z"
            />
            <path
              className="st0"
              d="M554.6,162.62c0.1-0.03,0.2-0.03,0.28,0.05c0.05,0.05,0.08,0.13,0.07,0.22c-0.22,2.72-2.66,3.85-5.27,3.85
					c-6.71,0-10.46-6.94-12.86-14.43c-0.97,0.09-2.04,0.09-2.96,0.09v13.97h-7.45v-32.48c3.61-0.19,7.17-0.33,10.78-0.33
					c5.97,0,13.1,1.48,13.19,8.84c0,4.31-3.19,7.4-6.85,8.79c2.41,4.49,6.8,11.61,10.04,11.61
					C553.94,162.81,554.27,162.74,554.6,162.62z M536.35,137.73c-0.83,0-1.66,0.09-2.49,0.23v10.23c0.88,0.09,1.71,0.14,2.59,0.14
					c3.47,0,6.29-1.62,6.29-5.27C542.74,139.22,539.87,137.73,536.35,137.73z"
            />
            <path
              className="st0"
              d="M500.34,148.42c-0.12,0.04-0.2,0.05-0.27,0.02c-0.06-0.04-0.1-0.1-0.11-0.17
					c-0.05-0.24-0.09-0.52-0.09-0.78c0-2.08,1.8-3.33,3.7-3.33c3.93,0,6.34,3.56,6.34,7.5c0,3.33-1.39,6.43-3.52,8.88
					c2.27,1.94,4.63,3.93,6.94,5.83h-9.9c-0.42-0.32-1.85-1.48-2.27-1.81c-2.68,1.57-5.88,2.59-9.16,2.59
					c-5.69,0-11.47-3.7-11.47-9.86c0-3.89,1.99-6.84,5.37-8.61c-1.39-1.8-2.5-4.16-2.5-6.25c0-6.34,5.92-9.21,11.38-9.21
					c2.13,0,4.26,0.18,6.39,0.46l-0.7,5.28c-1.48-0.46-3.05-0.69-4.58-0.69c-2.82,0-5.13,1.48-5.13,4.49
					c0,3.89,8.24,11.1,12.35,14.72c0.69-1.25,1.07-2.59,1.07-4.26c0-2.59-1.25-4.95-3.15-4.95
					C500.82,148.28,500.56,148.34,500.34,148.42z M488.17,151.85c-1.02,1.07-1.53,2.41-1.53,3.89c0,3.84,3.61,7.03,7.63,7.03
					c1.34,0,2.73-0.32,3.84-1.02C494.65,158.64,491.18,155.41,488.17,151.85z"
            />
            <path
              className="st0"
              d="M446.36,162.87c0.08-0.04,0.21-0.06,0.29,0c0.06,0.04,0.11,0.13,0.09,0.26c-0.27,2-1.91,3.61-4.65,3.61
					c-3.19,0-4.95-2.08-4.95-5.5v-29.57h6.85v29.2c0,1.2,0.37,2.18,1.53,2.18C445.84,163.05,446.12,162.98,446.36,162.87z"
            />
          </g>
        </g>
        <path
          className="st0"
          d="M68.12,83.84c3.06,0.6,6.52,1.79,9.3,3.31c8.43,4.64,11.58,11.99,10.65,20.75
			c-0.92,8.54-6.66,15.95-15.06,19.97c-5.18,2.47-11.14,3.9-18.24,4.43c-10.01,0.74-21.56,0.07-32.3-1.9V50.79
			c8.71-1.43,18.51-2.66,27.13-2.66c9.35,0.01,15.75,0.95,22.05,3.89c7.46,3.49,10.07,9.43,10.14,14.39
			C81.84,71.35,78.91,79.11,68.12,83.84z M48.21,122.05c6.41,0,9.81-1.25,12.76-2.94c5.29-3.05,8.32-9.32,7.7-15.84
			c-0.6-6.35-4.87-10.45-11.01-12.21c-4.5-1.28-10.75-1.26-17.16,0.44v29.82C42.62,121.8,45.75,122.05,48.21,122.05z M40.5,84.98
			c9.64-0.74,14.16-2.6,18.02-5.12c4.32-2.83,6.32-6.7,6.5-10.38c0.21-4.35-2.38-8.89-10.11-10.67c-4.07-0.93-9.49-0.9-14.4-0.2
			V84.98z M164.72,59.54c0.91-0.29,2.06-0.39,3.02-0.22c5.11,0.87,6.99,9.43,6.55,21.58c-0.51,13.98-5.99,36.82-16.13,36.93
			c-0.77,0.01-1.73,0-2.46-0.26l-10.03-67.88h-18.62l7.38,44.48c-1.35,8.91-5.21,21.82-13.15,23.16c-0.77,0.13-2.71,0.25-3.74,0.05
			l-10-67.69H88.92l13.61,81.96c4.58,1.16,9.6,1.23,13.86,0.29c10.19-2.26,18-12.21,20.54-22.83l3.74,22.54
			c4.44,1,7.09,0.99,10.96,0.93c10.24-0.17,18.39-5.79,24.02-14.33c8.69-13.21,12.31-32.2,12.31-47.79
			c0-12.8-3.88-22.37-13.51-22.37c-4.8,0-10.01,3.97-10.43,10.87c-0.02,0.24,0.03,0.42,0.2,0.54
			C164.35,59.59,164.56,59.59,164.72,59.54z"
        />
        <path
          className="st0"
          d="M227.84,19.78c2.42,29.43,3.46,52,3.46,71.01c0,19.01-1.1,41.58-3.46,71.01c-0.05,0.58-0.53,1.03-1.12,1.03
			c-0.58,0-1.07-0.45-1.12-1.03c-2.43-29.48-3.46-52.05-3.46-71.01c0-18.96,1.04-41.53,3.46-71.01c0.05-0.58,0.54-1.03,1.12-1.03
			C227.3,18.75,227.79,19.2,227.84,19.78z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M763.7,109.77c-3.38,0-5.9-2.55-5.9-6.08c0-3.44,2.52-6.07,5.9-6.07c3.35,0,5.84,2.68,5.84,6.07
			C769.54,107.18,767.05,109.77,763.7,109.77z M763.7,98.44c-2.72,0-4.89,2.21-4.89,5.24c0,3.05,2.14,5.31,4.89,5.31
			c2.72,0,4.83-2.27,4.83-5.31C768.52,100.71,766.36,98.44,763.7,98.44z M763.37,104.02c-0.29,0.05-0.63,0.02-0.91,0v3.5h-1.28
			v-7.84c0.73-0.05,1.41-0.08,2.09-0.08c1.34,0,2.94,0.37,2.94,2.05c0,1.09-0.77,1.84-1.68,2.16c0.92,1.68,1.68,2.88,2.28,2.88
			c0.08,0,0.16,0,0.24-0.04l0.08,0.06c0,0.04,0,0.07,0,0.1c0,0.34-0.29,0.74-0.85,0.74C764.92,107.55,764.14,105.86,763.37,104.02z
			 M763.1,100.42c-0.23,0-0.44,0.02-0.64,0.05v2.71c0.23,0.01,0.48,0.03,0.7,0.03c0.92,0,1.77-0.41,1.77-1.41
			C764.94,100.79,764.07,100.42,763.1,100.42z"
        />
      </g>
    </g>
    <g></g>
  </svg>
);
