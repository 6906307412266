import React, { FC, SVGProps } from 'react';

export const TMobile: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg2008"
    width="523"
    height="123"
    viewBox="44 334 523 123"
    overflow="visible"
    enableBackground="new 44 334 523 123"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="m 369.41871,41.449261 a 2.5044003,2.5044003 0 1 1 -5.0088,0 2.5044003,2.5044003 0 1 1 5.0088,0 z"
      transform="matrix(2.5267527,0,0,2.592437,-506.01172,255.1969)"
    />
    <rect
      id="rect3211"
      width="16.107"
      height="16.437"
      x="523.84003"
      y="391.16901"
    />
    <rect
      y="391.16901"
      x="204.39101"
      height="16.437"
      width="16.107"
      id="rect3213"
    />
    <rect
      y="391.16901"
      x="108.5"
      height="16.437"
      width="16.107"
      id="rect3217"
    />
    <path
      id="path3221"
      d="m 97.909,358.36 h 1.911 c 12.343,0 18.096,6.603 20.5,24.212 l 3.822,-0.169 -0.51,-27.938 H 60.824 l -0.619,27.938 3.678,0.169 c 0.637,-6.603 1.401,-10.159 3.059,-13.715 2.931,-6.604 9.048,-10.497 16.566,-10.497 h 2.676 v 60.784 c 0,6.434 -0.382,8.466 -1.91,9.99 -1.275,1.185 -3.824,1.693 -6.756,1.693 h -2.931 v 4.063 h 34.919 v -4.063 h -2.949 c -2.913,0 -5.48,-0.509 -6.736,-1.693 -1.529,-1.524 -1.911,-3.557 -1.911,-9.99 V 358.36"
    />
    <path
      id="path3223"
      d="m 264.053,434.891 20.35,-71.006 v 55.721 c 0,6.081 -0.346,8.218 -1.726,9.697 -1.036,1.15 -3.437,1.644 -6.099,1.644 h -1.035 v 3.944 h 28.765 v -3.944 h -1.497 c -2.646,0 -5.062,-0.493 -6.097,-1.644 -1.382,-1.479 -1.727,-3.616 -1.727,-9.697 v -47.502 c 0,-6.082 0.345,-8.383 1.727,-9.862 1.15,-0.986 3.451,-1.644 6.097,-1.644 h 1.497 v -3.781 h -22.321 l -16.799,57.364 -16.437,-57.364 h -22.206 v 3.781 h 2.07 c 6.099,0 7.249,1.315 7.249,9.205 v 45.2 c 0,7.232 -0.345,10.191 -1.381,12.328 -1.15,2.137 -3.583,3.616 -6.098,3.616 h -1.841 v 3.944 h 23.701 v -3.944 h -1.495 c -2.991,0 -5.408,-1.151 -6.805,-3.452 -1.364,-2.301 -1.709,-4.438 -1.709,-12.492 v -51.117 l 20.233,71.006 h 3.584"
    />
    <path
      id="path3225"
      d="m 329.044,379.335 c -14.267,0 -23.586,11.177 -23.586,28.765 0,16.93 9.319,28.436 23.226,28.436 14.035,0 23.355,-11.506 23.355,-28.6 0,-16.931 -9.32,-28.601 -22.995,-28.601 m -0.46,3.78 c 4.126,0 7.692,2.63 9.648,7.068 1.841,4.109 2.646,9.698 2.646,17.752 0,16.6 -4.027,24.818 -12.195,24.818 -8.153,0 -12.082,-8.219 -12.082,-24.983 0,-7.89 0.821,-13.478 2.662,-17.587 1.826,-4.273 5.524,-7.068 9.321,-7.068"
    />
    <path
      id="path3227"
      d="m 371.155,355.995 -16.339,0.822 v 3.616 h 0.789 c 4.833,0 5.885,1.644 5.885,9.369 v 53.419 c 0,6.903 -0.23,8.712 -1.15,11.67 h 3.797 c 2.859,-4.767 3.566,-5.588 4.717,-5.588 0.559,0 1.117,0.164 1.94,0.986 5.653,4.602 8.069,5.588 13.247,5.588 12.409,0 21.384,-11.999 21.384,-29.093 0,-16.108 -8.301,-26.956 -20.71,-26.956 -6.443,0 -11.144,3.123 -13.56,8.711 v -32.544 m 11.933,27.942 c 7.381,0 11.177,7.89 11.177,23.34 0,16.272 -3.912,24.655 -11.391,24.655 -8.284,0 -12.641,-8.548 -12.641,-24.162 0,-7.89 1.037,-13.313 3.453,-17.423 2.169,-3.944 5.868,-6.41 9.402,-6.41"
    />
    <path
      d="m 426.711,380.157 -16.782,0.986 v 3.616 h 1.266 c 4.815,0 5.852,1.644 5.852,9.205 v 27.778 c 0,7.562 -1.036,9.37 -5.852,9.37 h -1.825 v 3.779 h 25.051 v -3.779 h -1.809 c -4.849,0 -5.9,-1.645 -5.9,-9.37 v -41.585"
      id="path3229"
    />
    <path
      id="path3231"
      d="m 455.819,355.995 -16.782,0.822 v 3.616 h 1.251 c 4.832,0 5.867,1.644 5.867,9.369 v 51.939 c 0,7.726 -1.035,9.37 -5.867,9.37 h -1.825 v 3.779 h 25.065 v -3.779 h -1.841 c -4.849,0 -5.868,-1.645 -5.868,-9.37 v -65.746"
    />
    <path
      id="path3233"
      d="m 509.306,407.606 c -0.56,-17.423 -8.844,-28.271 -21.368,-28.271 -12.098,0 -20.826,11.834 -20.826,28.271 0,17.587 8.613,28.929 21.96,28.929 8.629,0 14.612,-4.603 19.56,-14.794 l -3.451,-1.808 c -4.027,8.547 -8.054,11.999 -14.268,11.999 -9.089,0 -12.984,-7.231 -13.101,-24.326 h 31.494 m -31.378,-4.11 c 0.099,-12.327 4.108,-20.052 10.568,-20.052 6.443,0 10.239,7.561 10.125,20.052 h -20.693"
    />
    <g id="g3235">
      <g id="g3237">
        <path
          d="m 545.988,377.995 c 0.972,0 1.921,0.249 2.847,0.748 0.926,0.499 1.647,1.213 2.163,2.141 0.518,0.928 0.776,1.896 0.776,2.904 0,0.998 -0.255,1.957 -0.765,2.877 -0.508,0.921 -1.223,1.636 -2.14,2.145 -0.919,0.509 -1.879,0.764 -2.882,0.764 -1.003,0 -1.963,-0.254 -2.882,-0.764 -0.918,-0.509 -1.634,-1.223 -2.144,-2.145 -0.512,-0.92 -0.768,-1.879 -0.768,-2.877 0,-1.008 0.259,-1.976 0.779,-2.904 0.519,-0.928 1.242,-1.643 2.166,-2.141 0.93,-0.499 1.879,-0.748 2.85,-0.748 z m 0,0.964 c -0.812,0 -1.604,0.208 -2.372,0.625 -0.769,0.417 -1.371,1.011 -1.806,1.785 -0.434,0.774 -0.65,1.581 -0.65,2.418 0,0.833 0.213,1.631 0.64,2.395 0.426,0.764 1.023,1.359 1.789,1.786 0.767,0.427 1.567,0.64 2.399,0.64 0.834,0 1.632,-0.213 2.399,-0.64 0.766,-0.426 1.361,-1.021 1.785,-1.786 0.424,-0.764 0.637,-1.562 0.637,-2.395 0,-0.837 -0.216,-1.644 -0.648,-2.418 -0.432,-0.773 -1.033,-1.368 -1.805,-1.785 -0.771,-0.417 -1.56,-0.625 -2.368,-0.625 z m -2.54,8.023 v -6.23 h 2.145 c 0.731,0 1.263,0.058 1.59,0.173 0.329,0.115 0.59,0.316 0.784,0.603 0.195,0.286 0.293,0.591 0.293,0.913 0,0.455 -0.164,0.852 -0.49,1.189 -0.324,0.337 -0.757,0.527 -1.296,0.568 0.221,0.092 0.397,0.202 0.53,0.33 0.252,0.245 0.559,0.657 0.922,1.235 l 0.761,1.22 h -1.233 L 546.899,386 c -0.435,-0.769 -0.784,-1.251 -1.047,-1.447 -0.184,-0.145 -0.453,-0.217 -0.806,-0.217 h -0.592 v 2.647 h -1.006 z m 1.007,-3.502 h 1.226 c 0.584,0 0.983,-0.087 1.197,-0.262 0.214,-0.175 0.319,-0.406 0.319,-0.694 0,-0.185 -0.051,-0.351 -0.154,-0.497 -0.103,-0.146 -0.244,-0.256 -0.428,-0.328 -0.182,-0.072 -0.52,-0.107 -1.013,-0.107 h -1.147 v 1.888 z"
          id="path3239"
        />
      </g>
    </g>
  </svg>
);
