import {
  Ahip,
  ClearEstateLogo,
  Goodbill,
  Marble,
} from 'component-library/components/icons';
import { FC } from 'react';
import styled from 'styled-components';

const styledPartnerLogo = (icon: FC) =>
  styled(icon).attrs({
    width: 250,
  })`
    align-self: center;
  `;

export const StyledGoodbillLogo = styledPartnerLogo(Goodbill);
export const StyledClearEstateLogo = styledPartnerLogo(ClearEstateLogo);
export const StyledAhipLogo = styledPartnerLogo(Ahip);
export const StyledMarbleLogo = styledPartnerLogo(Marble);
