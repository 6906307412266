import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { body, buttonCta, heading, linkTo } from './content';

const SpendingCardWrapper = styled(ShowOn)`
  background: ${colors.navyBlue};
  border-radius: 24px;
  padding: 32px 16px;
  margin: 0 16px 64px;
`;

export const SpendingCardSmall = () => (
  <SpendingCardWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading marginBottom={16} variant={4} color={colors.white}>
      {heading}
    </Heading>
    <Body marginBottom={48} variant={3} color={colors.white}>
      {body}
    </Body>
    <LinkWrapper to={linkTo}>
      <PrimaryButton withCaret buttonColor={ButtonColor.Peach}>
        {buttonCta}
      </PrimaryButton>
    </LinkWrapper>
    <StaticImage src="../images/spending-card-small.webp" alt="spending-card" />
  </SpendingCardWrapper>
);
