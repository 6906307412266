import { TabbedCard } from 'component-library/components/cards/tabbed-card/TabbedCard';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { MORE_WAY_TO_SAVE_TABS } from 'components/pages/benefits/more-ways-to-save/moreWaysToSaveUtils';
import React from 'react';
import styled from 'styled-components';

const WaysToSaveWrapper = styled(ShowOn)`
  max-width: 1680px;
  padding: 40px;
  margin: 0 auto 20px;
`;

export const WaysToSaveLarge = () => (
  <WaysToSaveWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <TabbedCard tabs={MORE_WAY_TO_SAVE_TABS} />
  </WaysToSaveWrapper>
);
