import React from 'react';

import { HighlightsLarge } from './HighlightsLarge';
import { HighlightsSmall } from './HighlightsSmall';

export const Highlights = () => (
  <>
    <HighlightsSmall />
    <HighlightsLarge />
  </>
);
