import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface StyledTabProps {
  marginLeft?: boolean;
  selected: boolean;
}

const StyledTab = styled.div<StyledTabProps>`
  border-left: 1.5px solid ${colors.navyBlue};
  border-top: 1.5px solid ${colors.navyBlue};
  border-right: 1.5px solid ${colors.navyBlue};
  cursor: pointer;
  padding: 9px 32px 8px;
  background: ${({ selected = false }) =>
    selected ? colors.peach : colors.white};
  border-radius: 20px 20px 0px 0px;
  margin-left: ${({ marginLeft = false }) => (marginLeft ? '12px' : '0px')};
`;

interface TabItemProps {
  tabLabel: string;
  onClick?: () => void;
  index: number;
  selected: boolean;
}

export const Tab: FC<TabItemProps> = ({
  tabLabel,
  onClick,
  index,
  selected,
}) => (
  <StyledTab onClick={onClick} marginLeft={index !== 0} selected={selected}>
    <Body variant={4}>{tabLabel}</Body>
  </StyledTab>
);
